import {GroupMember, TaskAssignees} from 'shared/models/task';

function getRequiredMemberFields(members: GroupMember[]) {
  return members.map<GroupMember>(({memberId, memberRole}) => ({
    memberId,
    memberRole,
  }));
}

function filterExistingMembers(firstCollection: GroupMember[], secondCollection: GroupMember[]) {
  return firstCollection.filter((sourceItem) => {
    return !secondCollection.find(
      (collectionItem) =>
        collectionItem.memberId === sourceItem.memberId && collectionItem.memberRole === sourceItem.memberRole,
    );
  });
}

export const getMembersForAdd = (members: TaskAssignees[], localMembers: TaskAssignees[]) => {
  const membersToDelete = getRequiredMemberFields(filterExistingMembers(members, localMembers));
  const membersToAdd = getRequiredMemberFields(filterExistingMembers(localMembers, members));
  return {membersToAdd, membersToDelete};
};

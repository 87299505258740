import {AxiosError, AxiosResponse} from 'axios';
import {camelize} from 'humps';

import {getApiResponseDataRange} from 'shared/helpers/common';
import {BasicApiError, DetailedApiError, GeneralizedApiError} from 'shared/models/apiError';

export function isAxiosError<T>(payload: unknown): payload is AxiosError<T> {
  // TODO: Upgrade to latest axios
  // Same implementation as axios.isAxiosError(payload) due to a bug in axios v0.21
  // https://github.com/axios/axios/issues/3815
  const error = payload as AxiosError<T>;
  return typeof error === 'object' && error.isAxiosError === true && error.response !== undefined;
}

function isErrorWithData(data: unknown): data is GeneralizedApiError {
  return (
    typeof data === 'string' ||
    (typeof data === 'object' && data !== null && ('detail' in data || 'message' in data || 'error' in data))
  );
}

function isBasicApiError(data: unknown): data is BasicApiError {
  return data && typeof data === 'object' && ('message' in data || 'error' in data);
}

function isDetailedApiError(data: unknown): data is DetailedApiError {
  return data && typeof data === 'object' && 'detail' in data;
}

export const UNEXPECTED_ERROR = 'Unexpected error';

type ExtractedError = Record<string, string> | string;

export function extractAxiosError(error: unknown, errorsMapping?: Record<string, string>): ExtractedError {
  if (isAxiosError<GeneralizedApiError>(error) && isErrorWithData(error.response.data)) {
    const errorData = error.response.data;

    // Directly return if data is a string
    if (typeof errorData === 'string') {
      return errorData;
    }

    // Handle DetailedApiError
    if (isDetailedApiError(errorData)) {
      if (typeof errorData.detail === 'string') {
        return errorData.detail;
      } else if (Array.isArray(errorData.detail)) {
        const errors = errorData.detail.reduce((prev, cur) => {
          const errorField = errorsMapping?.[cur.field] || camelize(cur.field);
          return {...prev, [errorField]: cur.error};
        }, {});
        return Object.keys(errors).length ? errors : UNEXPECTED_ERROR;
      }
    }

    // Handle BasicApiError
    if (isBasicApiError(errorData)) {
      return errorData.message || errorData.error || UNEXPECTED_ERROR;
    }
  }

  // Handle everything else
  return UNEXPECTED_ERROR;
}

export function stringifyAxiosError(error: unknown, useFirstError = false) {
  const extract = extractAxiosError(error);
  if (typeof extract === 'string') return extract;
  if (useFirstError) {
    return Object.values(extract)[0];
  }
  return Object.values(extract).join('\r\n');
}

export async function fetchAllBlocking<R>(request: (offset: number, take: number) => Promise<AxiosResponse<R[]>>) {
  return fetchAll(request, 100, null, null).promise;
}

function fetchAll<R>(
  request: (offset: number, take: number) => Promise<AxiosResponse<R[]>>,
  take = 100,
  handleFirstResponse: (data: R[]) => void | null,
  handleResponse: (data: R[]) => void | null,
) {
  // TODO: add axios abort controller
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let interrupt = false;
  const fetcher = async () => {
    const first = await request(0, take);
    handleFirstResponse && handleFirstResponse(first.data);
    const {total} = getApiResponseDataRange(first);
    const chunks = Math.ceil((total - take) / take);
    const requests = Array.from({length: chunks}).map((_, i) => request(i * take + take, take));
    const rest = await Promise.all(requests);
    const restData = rest.reduce((acc, cur) => acc.concat(cur.data), [] as R[]);
    handleResponse && handleResponse(restData);

    return first.data.concat(restData) as R[];
  };
  const cancel = () => {
    interrupt = true;
  };
  return {
    cancel,
    promise: fetcher(),
  };
}

export type FetchAllGeneratorOptions<R> = {
  request: (offset: number, take: number) => Promise<AxiosResponse<R[]>>;
  initialTake?: number;
  maxTake?: number;
  maxRetries?: number;
  retryDelay?: number;
  abortSignal?: AbortSignal;
  initialParallelCalls?: number;
  maxParallelCalls?: number;
};

export async function* fetchAllGenerator<R>(options: FetchAllGeneratorOptions<R>): AsyncGenerator<R[], void, unknown> {
  const {
    request,
    initialTake = 500,
    maxTake = 2000,
    maxRetries = 3,
    retryDelay = 1000,
    abortSignal,
    initialParallelCalls = 1,
    maxParallelCalls = 5,
  } = options;

  let offset = 0;
  let totalItems: number | undefined;
  let parallelCalls = initialParallelCalls;
  let take = initialTake;

  const fetchWithRetry = async (offset: number, take: number): Promise<AxiosResponse<R[]>> => {
    let retries = 0;
    while (retries <= maxRetries) {
      try {
        if (abortSignal?.aborted) {
          throw new Error('Operation aborted');
        }
        return await request(offset, take);
      } catch (error: unknown) {
        if ((error as Error).message === 'Operation aborted' || (error as Error).name === 'AbortError') {
          throw new Error('Operation aborted');
        }
        retries++;
        if (retries > maxRetries) {
          throw error;
        }
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
      }
    }
    throw new Error('Unexpected error in fetchWithRetry');
  };

  const adjustFetchParameters = (itemsReceived: number, timeTaken: number) => {
    const itemsPerSecond = itemsReceived / (timeTaken / 1_000);
    const targetItemsPerSecond = 1_000;

    if (itemsPerSecond < targetItemsPerSecond * 0.8) {
      // If we're fetching too slowly, increase parallel calls or take
      if (parallelCalls < maxParallelCalls) {
        parallelCalls = Math.min(maxParallelCalls, parallelCalls + 1);
      } else if (take < maxTake) {
        take = Math.min(maxTake, take * 1.5);
      }
    } else if (itemsPerSecond > targetItemsPerSecond * 1.2) {
      // If we're fetching too quickly, decrease parallel calls or take
      if (parallelCalls > 1) {
        parallelCalls--;
      } else if (take > initialTake) {
        take = Math.max(initialTake, take / 1.5);
      }
    }
  };

  while (true) {
    if (abortSignal?.aborted) {
      throw new Error('Operation aborted');
    }

    const fetchPromises = Array.from({length: parallelCalls}, (_, i) => fetchWithRetry(offset + i * take, take));

    const startTime = Date.now();
    try {
      const results = await Promise.all(fetchPromises);
      const endTime = Date.now();
      let allItems: R[] = [];

      for (const response of results) {
        if (totalItems === undefined) {
          totalItems = parseInt(response.headers['x-total-count'] as string, 10) || undefined;
        }
        allItems = allItems.concat(response.data);
        if (response.data.length < take) {
          yield allItems;
          return;
        }
      }

      yield allItems;

      offset += allItems.length;
      adjustFetchParameters(allItems.length, endTime - startTime);

      if (totalItems !== undefined && offset >= totalItems) {
        return;
      }
    } catch (error) {
      throw error;
    }
  }
}

class FetchManager {
  private abortController: AbortController | null = null;

  createNewAbortController() {
    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortController = new AbortController();
    return this.abortController.signal;
  }

  abort() {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null;
    }
  }

  get signal() {
    return this.abortController?.signal;
  }
}

export const fetchManager = new FetchManager();

export async function fetchAllWithGenerator<R>(
  options: FetchAllGeneratorOptions<R>,
  fetchManager?: FetchManager,
): Promise<R[]> {
  const allItems: R[] = [];
  const signal = fetchManager ? fetchManager.createNewAbortController() : options.abortSignal;

  try {
    for await (const items of fetchAllGenerator({...options, abortSignal: signal})) {
      if (signal?.aborted) {
        throw new Error('Operation aborted');
      }
      allItems.push(...items);
    }
    return allItems;
  } catch (error) {
    if (error instanceof Error && (error.name === 'AbortError' || error.message === 'Operation aborted')) {
      return allItems;
    }
    throw error;
  }
}

import {FormikProps} from 'formik';
import {MutableRefObject} from 'react';
import {QueryClient} from 'react-query';

import {OptionType} from '../components';
import {CompanyWorker, CompanyWorkerRole, InviteBy, WorkerFormValues} from '../models/worker';

export const updateCompanyOrgsList = (orgs: string[], queryClient: QueryClient) => {
  if (!Array.isArray(orgs)) return;
  const cachedOrgsList = (queryClient.getQueryData('orgs') || []) as OptionType[];
  if ((!cachedOrgsList.length && orgs.length) || cachedOrgsList.find((org) => !orgs.includes(org.value)))
    return queryClient.refetchQueries('orgs');
};

export function needShowEmailContact(profile: CompanyWorker) {
  if (!profile) return false;
  return (profile.roles.includes('company_admin') && !!profile.workerFull?.email) || !profile.workerFull?.mobileNumber;
}

function getOnlyChangedFields<T, R>(current: T, target: R, exceptionKeys?: Array<string>) {
  const diff = {};
  for (const key of Object.keys(current)) {
    if (exceptionKeys.includes(key) || current[key] !== target[key]) {
      diff[key] = current[key];
    }
  }
  return diff;
}

export const getWorkerMixpanelLabel = (isProjectWorker: boolean) => (isProjectWorker ? 'Project' : '');

export const getInviteMemberHandler = (
  formik: MutableRefObject<FormikProps<WorkerFormValues>>,
  projectId: string,
  submitInvite,
) => {
  async function handleSubmit(values: WorkerFormValues) {
    if (values.inviteBy === InviteBy.Email) {
      delete values.phoneNumber;
      delete values.phoneCode;
      values.sendSms = false;
    } else {
      delete values.workerEmail;
      values.sendEmail = false;
    }

    const initialValues = formik.current.initialValues;
    const changedValues: Partial<WorkerFormValues> = getOnlyChangedFields(values, initialValues, [
      'roles',
      'phoneCode',
      'phoneNumber',
    ]);

    const inviteValues = Object.assign(changedValues, {
      projectId,
    });

    return await submitInvite(inviteValues);
  }

  const selectRole = (fieldName: string, value: CompanyWorkerRole) => {
    const updateFields = {...formik.current.values, [fieldName]: [value]};

    if (['company_admin', 'project_admin'].includes(value)) {
      updateFields.inviteBy = InviteBy.Email;
      updateFields.sendEmail = true;
    }
    formik.current.setValues(updateFields);
  };

  const checkIsAdmin = (roles: CompanyWorkerRole[]) =>
    roles.includes('project_admin') || roles.includes('company_admin');

  return {handleSubmit, checkRole: selectRole, checkIsAdmin};
};

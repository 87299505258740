import * as Sentry from '@sentry/react';
import {lazy, Suspense, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {generatePath, Redirect, Route as ReactRouterRoute, Switch, useRouteMatch, withRouter} from 'react-router';
import {useHistory} from 'react-router-dom';

import CollabLanding from 'modules/CollabLanding';
import CompanyProfile from 'modules/CompanyProfile';
import ComputerStart from 'modules/ComputerStart/ComputerStart';
import Contact from 'modules/Contact';
import ForgotPassword from 'modules/ForgotPassword';
import Landing from 'modules/Landing';
import MobileAccountComplete from 'modules/MobileAccountComplete/MobileAccountComplete';
import OAuth from 'modules/OAuth';
import Project from 'modules/Project';
import Projects from 'modules/Projects';
import ResetPassword from 'modules/ResetPassword';
import {SharedTask} from 'modules/SharedTask';
import SignIn from 'modules/SignIn';
import SignUp from 'modules/SignUp';
import GetStartedSignup from 'modules/SignUp/Alternative/GetStarted';
import CongratsPopup from 'modules/SignUp/CongratsPopup';
import Tasks from 'modules/Tasks';
import DailyRisk from 'modules/Tasks/DailyRisk/DailyRisk';
import Worker from 'modules/Worker';
import Workers from 'modules/Workers';
import {Loader, ProfileLoader} from 'shared/components';
import ProtectedRoute from 'shared/components/ProtectedRoute';
import UnauthenticatedRoute from 'shared/components/UnauthenticatedRoute';
import {ROUTES, useLocalizedRoutes} from 'shared/constants/routes';
import {useClassName, useCompanyDataLoader} from 'shared/hooks';
import {useEffectAfterMount, useMount} from 'shared/hooks/core';
import {useSentryUserInfo} from 'shared/hooks/useSentryUserInfo';
import {Layout as AdminLayout} from 'shared/layout/admin';
import {useRootDispatch, useRootSelector} from 'store';
import {hasAdminRoleSelector, profileActions, selectActiveCompany} from 'store/profile';
import {detectActiveWorkspace, loadWorkerCompanies} from 'store/profile/actions';
import {companyAndProjectsReady} from 'store/selectors';

import ApplyAuthCode from './modules/ApplyAuthCode';
import InviteSignup from './modules/InviteSignup';
import Subcontractors from './modules/Subcontractors/Subcontractors';
import MyProfile from './modules/Worker/Profile';
import {CompanyStatus} from './shared/models/company';

const Dashboard = lazy(() => import('modules/Dashboard'));
const Route = Sentry.withSentryRouting(ReactRouterRoute);

const CompanyProfileRoute = () => {
  const dispatch = useRootDispatch();
  useMount(() => {
    dispatch(profileActions.setCompanyProfileOpened(true));
  });
  return null;
};

const MyProfileRoute = () => {
  const dispatch = useRootDispatch();
  useMount(() => {
    dispatch(profileActions.setMyProfileOpened(true));
  });
  return null;
};

const Routes = withRouter(() => {
  const routes = useLocalizedRoutes();
  const dispatch = useRootDispatch();
  const history = useHistory();
  const {
    profile,
    company,
    displayCongratsPopup,
    companyProfileOpened,
    isAdmin,
    companies,
    appReady,
    showAccessDeniedPopup,
    myProfileOpened,
  } = useRootSelector((state) => ({
    profile: state.profile.worker,
    companyWorkers: state.profile.companyWorkers,
    isAdmin: hasAdminRoleSelector(state),
    company: selectActiveCompany(state),
    companyProfileOpened: state.profile.companyProfileOpened,
    myProfileOpened: state.profile.myProfileOpened,
    displayCongratsPopup: state.profile.showCongratsPopup,
    companies: state.profile.companies,
    appReady: companyAndProjectsReady(state),
    showAccessDeniedPopup: state.profile.showAccessDeniedPopup,
  }));
  const isProjectsPage = useRouteMatch(routes.projects);

  useClassName(document.getElementById('root') as HTMLElement, appReady ? 'app-ready' : '');

  useEffect(() => {
    if (!company) {
      dispatch(detectActiveWorkspace());
    }
  }, [dispatch, profile, company, companies]);

  useEffectAfterMount(() => {
    if (showAccessDeniedPopup && !isProjectsPage?.isExact) {
      // need to re-fetch companies if the status of the active company is different
      if (company?.status !== CompanyStatus.Disabled) {
        dispatch(loadWorkerCompanies(profile?.id ?? ''));
      }
      history.push(routes.projects);
    }
  }, [showAccessDeniedPopup]);

  const hideCongratsPopup = () => dispatch(profileActions.showCongratsPopup(false));
  useCompanyDataLoader();
  useSentryUserInfo(profile);

  return (
    <>
      <Switch>
        {/* <Route path={ROUTES.landing} component={Home} exact /> */}
        <Route path={ROUTES.landing} component={Landing} exact />
        <Route path={ROUTES.collaboration} component={CollabLanding} exact />
        <Route path={ROUTES.enterpriseContact} component={Contact} exact />
        <Route path={ROUTES.demoContact} component={Contact} exact />
        <Route path={ROUTES.sharedTask} component={SharedTask} />
        <UnauthenticatedRoute path={ROUTES.getStarted} component={GetStartedSignup} exact />
        <UnauthenticatedRoute path={ROUTES.logIn} component={SignIn} />
        <UnauthenticatedRoute path={ROUTES.signUp} component={SignUp} />
        <UnauthenticatedRoute path={ROUTES.signUpAltA} component={SignUp} />
        <UnauthenticatedRoute path={ROUTES.forgotPassword} component={ForgotPassword} />
        <UnauthenticatedRoute path={ROUTES.resetPassword} component={ResetPassword} />
        <UnauthenticatedRoute path={ROUTES.inviteSignup} component={InviteSignup} />
        <Route path={ROUTES.applyAuthCode} component={ApplyAuthCode} />
        <Route path={ROUTES.mobileAccountComplete} component={MobileAccountComplete} />
        <Route path={ROUTES.computerStart} component={ComputerStart} />
        <ProtectedRoute path={ROUTES.oauth} component={OAuth} />
        <ProtectedRoute
          render={(props) => {
            return (
              <ProfileLoader>
                <Helmet>
                  <title>{`C4${company?.companyName ? ' - ' + company?.companyName : ''}`}</title>
                </Helmet>

                <AdminLayout>
                  <ProtectedRoute
                    checkAppRoles
                    roles={['company_admin', 'project_admin', 'foreman', 'worker']}
                    render={() => (
                      <>
                        <Suspense fallback={<Loader />}>
                          <Switch>
                            <ProtectedRoute path={ROUTES.tasks} component={Tasks} />
                            <ProtectedRoute path={ROUTES.dailyRisk} component={DailyRisk} />
                            <ProtectedRoute roles={['company_admin']} path={ROUTES.workers} component={Workers} />
                            <ProtectedRoute
                              path={ROUTES.projectWorkers}
                              roles={['company_admin', 'project_admin']}
                              component={Workers}
                            />
                            <ProtectedRoute
                              path={ROUTES.subcontractor}
                              roles={['company_admin', 'project_admin']}
                              component={Subcontractors}
                            />
                            <ProtectedRoute
                              path={ROUTES.subcontractors}
                              roles={['company_admin', 'project_admin']}
                              component={Subcontractors}
                            />
                            <ProtectedRoute path={[ROUTES.projects, ROUTES.companyProfile]} component={Projects} />
                            <ProtectedRoute roles={['company_admin']} path={ROUTES.reports} component={Dashboard} />
                            <Redirect to={generatePath(routes.projects)} />
                          </Switch>
                        </Suspense>
                        <ProtectedRoute
                          exact
                          checkAppRoles={true}
                          path={ROUTES.worker}
                          component={Worker}
                          roles={['company_admin', 'project_admin']}
                        />
                        <ProtectedRoute
                          exact
                          path={ROUTES.projectWorker}
                          component={Worker}
                          roles={['company_admin', 'project_admin']}
                        />
                        <ProtectedRoute exact path={ROUTES.project} component={Project} />
                      </>
                    )}
                  />
                  <ProtectedRoute
                    checkAppRoles
                    roles={['company_admin']}
                    path={ROUTES.companyProfile}
                    component={CompanyProfileRoute}
                  />
                  <CompanyProfile
                    visible={companyProfileOpened}
                    onClose={() => {
                      if (!isAdmin) return;
                      if (props.location.pathname === ROUTES.companyProfile) {
                        props.history.push(routes.projects);
                      }
                      dispatch(profileActions.setCompanyProfileOpened(false));
                    }}
                  />
                  <ProtectedRoute path={ROUTES.profile} component={MyProfileRoute} />
                  {myProfileOpened && <MyProfile />}
                </AdminLayout>
              </ProfileLoader>
            );
          }}
        />
        <Redirect to={routes.logIn} />
      </Switch>
      <CongratsPopup visible={displayCongratsPopup} onClose={hideCongratsPopup} />
    </>
  );
});
export default Routes;

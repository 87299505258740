import {UserCredential} from '@firebase/auth';
import * as Sentry from '@sentry/browser';
import {ConfirmationResult} from 'firebase/auth';
import {Field, FieldProps, Formik, FormikProps} from 'formik';
import {TFunction} from 'i18next';
import React, {ChangeEvent, FC, KeyboardEvent, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import InputMask from 'react-input-mask';
import {batch, useSelector} from 'react-redux';
import {generatePath} from 'react-router';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {ProjectsApi} from 'api';
import WorkerApi, {SupportedLoginTypes} from 'api/worker';
import FirebaseService from 'services/Firebase';
import {Button, FormControl, Loader, MetaTags, PhoneInput, useConfirm} from 'shared/components';
import CountryCodeSelector from 'shared/components/CoreForm/CountryCodeSelector';
import EmailInput from 'shared/components/CoreForm/EmailInput';
import {usePasswordVisibility} from 'shared/components/CoreForm/hooks/usePasswordVisibility';
import MultiFactor from 'shared/components/MultiFactorDialog';
import ZetOnboardingContainer from 'shared/components/OnboardingLayout/ZetOnboardingContainer';
import {META_KEYWORDS, splitPhoneByCountryCode} from 'shared/constants/common';
import {getHumanizedFbError, isAuthMultiFactorError, isFirebaseAuthError} from 'shared/constants/firebase';
import {useLocalizedRoutes} from 'shared/constants/routes';
import useCountDown from 'shared/helpers/hooks/useCountDown';
import {useRecaptcha} from 'shared/helpers/hooks/useRecaptcha';
import {useAnalyticsService, useClassName, useLandingStyles, useParsedQuery} from 'shared/hooks';
import {useEffectOnce, useScrollToTopOnMount} from 'shared/hooks/core';
import useUserAgent from 'shared/hooks/useUserAgent';
import {OnboardingLayout} from 'shared/layout/base/OnboardingLayout';
import {LoginForm} from 'shared/models/loginForm';
import {Worker} from 'shared/models/worker';
import {RootState, useRootDispatch} from 'store';
import {
  loginConfirmAuth,
  loginProcess,
  loginSuccessed,
  loginWorkerByEmail,
  requestingPhoneCodeFailed,
  requestingPhoneCodeInProgress,
  requestingPhoneCodeSuccess,
} from 'store/ducks/auth/actions';
import {profileActions} from 'store/profile';
import {loadWorkerCompanies, loadWorkerCompanyWorkers, saveResumeChanges} from 'store/profile/actions';

const RECAPTCHA_CONTAINER_ID = 'signin-recaptcha-container';

enum AuthType {
  phone = 'phone',
  email = 'email',
}

const SignInPage: FC = () => {
  const {mixpanel, useZoomInfo} = useAnalyticsService({publicPage: true});
  useZoomInfo();

  const {t} = useTranslation(['sign_in', 'errors']);
  const routes = useLocalizedRoutes();
  const dispatch = useRootDispatch();
  const history = useHistory();
  const location = useLocation<{redirectUrl: string}>();
  const recaptcha = useRecaptcha();
  const mixpanelSignInEvents = mixpanel.events.signin;
  const mixpanelForgotPasswordEvents = mixpanel.events.forgotPassword;
  const userAgent = useUserAgent();
  const {confirm} = useConfirm();
  const {
    cbId: collabId,
    wrkrId: workerId,
    pid: projectId,
    email: emailFromQuery,
    phoneNumber: phoneNumberFromQuery,
  } = useParsedQuery<{cbId: string; wrkrId: string; pid: string; email: string; phoneNumber: string}>({
    defaultParams: {cbId: null, wrkrId: null, pid: null, email: null, phoneNumber: null},
    schema: {
      cbId: 'string',
      wrkrId: 'string',
      pid: 'string',
      email: 'string',
      phoneNumber: 'string',
    },
  });

  useScrollToTopOnMount();

  useEffectOnce(() => {
    mixpanel.track(mixpanelSignInEvents.page);
  });

  useLandingStyles();
  useClassName(document.body, 's-page', {clearInitialClasses: false});

  const {loginInProgress, isRequestingPhoneCode} = useSelector((state: RootState) => {
    return {
      loginInProgress: state.auth.loginInProgress,
      isRequestingPhoneCode: state.auth.isRequestingPhoneCode,
    };
  });

  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult>(null);
  const formik = useRef<FormikProps<LoginForm>>(null);
  const [countryCode, setCountryCode] = useState<string>(
    !phoneNumberFromQuery ? '+1' : splitPhoneByCountryCode(phoneNumberFromQuery)[0],
  );
  const {counter, start, reset} = useCountDown(15);
  const verifyCodeInputRef = useRef(null);
  const phoneFormRef = useRef(null);
  const [loginType, setLoginType] = useState<AuthType>(params?.get('email') ? AuthType.email : AuthType.phone);
  const passwordVisibility = usePasswordVisibility();

  const validationSchema = useMemo(() => {
    let blueprint = {};
    if (loginType === AuthType.phone) {
      blueprint = Object.assign(
        {},
        {
          verificationCode: showConfirm
            ? Yup.string().required(t('sign_in:validation.verificationCode.required', 'This field is required'))
            : undefined,
          phoneNumber: Yup.string().required(t('sign_in:validation.phoneNumber.required', 'This field is required')),
        },
      );
    } else if (loginType === AuthType.email) {
      blueprint = Object.assign(
        {},
        {
          email: Yup.string()
            .email(t('sign_in:validation.email.valid', 'Must be a valid email'))
            .required(t('sign_in:validation.email.required', 'This field is required')),
          password: Yup.string()
            .min(6, t('sign_in:validation.password.min', 'Must be at least 6 characters long'))
            .max(255, t('sign_in:validation.password.max', 'Must not exceed 255 characters long')),
        },
      );
    }

    return Yup.object().shape(blueprint);
  }, [loginType, showConfirm, t]);

  // send code to the user
  async function sendVerificationCode(values: LoginForm) {
    dispatch(requestingPhoneCodeInProgress());
    FirebaseService.toggleRecapchaAutoVerificationForTesting(values.phoneNumber);
    const recaptchaVerifier = recaptcha.create(RECAPTCHA_CONTAINER_ID, values.phoneNumber);
    try {
      const result = await FirebaseService.signInWithPhone(`${countryCode}${values.phoneNumber}`, recaptchaVerifier);
      setConfirmationResult(result);
      dispatch(requestingPhoneCodeSuccess());

      // timer
      reset();
      start();

      setShowConfirm(true);

      verifyCodeInputRef.current.focus();
    } catch (error) {
      Sentry.setExtra('loginForm', {...values});
      Sentry.captureException(error);
      setShowConfirm(false);
      dispatch(requestingPhoneCodeFailed());

      const humanaziedFbError = getHumanizedFbError(error, t as TFunction);
      console.error(humanaziedFbError);
      toast(humanaziedFbError, {
        position: 'bottom-right',
        closeOnClick: true,
        draggable: true,
      });
    } finally {
      recaptcha.destroy(recaptchaVerifier, RECAPTCHA_CONTAINER_ID);
      !isRequestingPhoneCode && dispatch(requestingPhoneCodeFailed());
    }
  }

  const afterSuccessLogin = async (worker: Worker) => {
    const [companies, companyWorkers] = await Promise.all([
      dispatch(loadWorkerCompanies(worker.id)),
      dispatch(loadWorkerCompanyWorkers(worker.id)),
    ]);

    if (!loadWorkerCompanyWorkers.fulfilled.match(companyWorkers) || !companies.length) {
      FirebaseService.signOut();
    } else {
      const token = await FirebaseService.getUserIdToken();
      batch(async () => {
        dispatch(profileActions.setWorker(worker));
        if (!worker?.agreeDigestSms || !worker?.agreePrivacyPolicy) {
          dispatch(
            saveResumeChanges({
              agreePrivacyPolicy: true,
              agreeDigestSms: true,
            }),
          );
        }
        if (!companyWorkers.payload.length) {
          dispatch(profileActions.setCreateNewCompany(true));
        }
      });

      dispatch(
        loginSuccessed({
          workerId: worker.id,
          token: token,
        }),
      );
      FirebaseService.setWorkerIdToLS(worker.id);

      if (userAgent.device.type === 'mobile') {
        history.push(generatePath(routes.mobileAccountComplete));
      } else {
        const defRedirectUrl = location.state?.redirectUrl ? location.state.redirectUrl : routes.projects;
        if (collabId && projectId && worker.id === workerId) {
          try {
            Promise.all([
              await ProjectsApi.getCollaborationInfo(projectId, collabId),
              await ProjectsApi.getProject(projectId),
            ]).then(([collabInfo, project]) => {
              dispatch(profileActions.setActiveCompany(project.companyId));
              const redirect = {
                pathname: collabInfo ? generatePath(routes.tasks, {projectId}) : defRedirectUrl,
                search: collabInfo?.viewFilters ? collabInfo.viewFilters : '',
              };
              history.push(redirect);
            });
            return;
          } catch (e) {
            Sentry.captureException(e);
            history.push(defRedirectUrl, {state: {autoCreateProject: true}});
          }
        }
        history.push(defRedirectUrl, {state: {autoCreateProject: true}});
        return;
      }
    }
  };

  async function verifyCode(values: LoginForm) {
    const {verificationCode} = values;
    try {
      dispatch(loginProcess());
      // TODO: Need to get rid of this casting hack
      const workerObject = (await dispatch(
        loginConfirmAuth({
          confirmationCode: verificationCode,
          confirmationResult,
        }),
      )) as Worker;
      await afterSuccessLogin(workerObject);
    } catch (error) {
      Sentry.setExtra('loginForm', {...values});
      Sentry.captureException(error);

      formik.current.setValues({
        ...formik.current.values,
        verificationCode: '',
      });
      setShowConfirm(false);
      toast(getErrorMessage(error));
    }
  }

  const loginWithEmailAndPassword = async (email: string, password: string) => {
    dispatch(loginProcess());
    try {
      const workerObject = await dispatch(loginWorkerByEmail({email, password}));
      await afterSuccessLogin(workerObject);
    } catch (error) {
      if (isAuthMultiFactorError(error)) {
        const resolver = FirebaseService.getMultiFactorResolverByError(error);
        const userCreds = (await confirm({
          title: '2FA SMS',
          component: <MultiFactor multiFactorResolver={resolver} />,
        })) as UserCredential;
        if (userCreds) {
          await onSuccessMultiFactorAuth(userCreds);
          return;
        }
      }
      Sentry.setExtra('loginForm', {email});
      Sentry.captureException(error);

      formik.current.setValues({
        ...formik.current.values,
        password: '',
      });
      setShowConfirm(false);
      toast(getErrorMessage(error));
    }
  };

  const onSuccessMultiFactorAuth = async (userCredential: UserCredential) => {
    const isRegistered = await WorkerApi.checkSignUp(userCredential.user.uid);
    if (isRegistered) {
      const workerObj = (await WorkerApi.loginOrSignupWorker({
        user: userCredential.user,
        idToken: await userCredential.user.getIdToken(),
        loginType: 'password' as SupportedLoginTypes,
      })) as Worker;
      await afterSuccessLogin(workerObj);
    }
  };

  function handleEnterPress(e: KeyboardEvent, action: 'sendCode' | 'checkCode' | 'loginWithEmailAndPassword') {
    if (e.key === 'Enter' && !loginInProgress) {
      e.preventDefault();
      e.stopPropagation();

      if (action === 'sendCode') {
        if (!formik.current.errors.phoneNumber && ((showConfirm && !counter) || (!showConfirm && counter))) {
          mixpanel.track(mixpanelSignInEvents.buttons.sendCode);
          sendVerificationCode(formik.current.values);
          return;
        }
        formik.current.setTouched({phoneNumber: true});
      } else if (action === 'checkCode') {
        if (formik.current.isValid) {
          mixpanel.track(mixpanelSignInEvents.buttons.phoneEnter);
          verifyCode(formik.current.values);
          return;
        }
        formik.current.setTouched({verificationCode: true});
      } else if (action === 'loginWithEmailAndPassword') {
        if (formik.current.isValid) {
          mixpanel.track(mixpanelSignInEvents.buttons.emailEnter);
          formik.current.submitForm();
          return;
        }
        formik.current.setTouched({email: true, password: true});
      }
    }
  }

  async function submit(values: LoginForm) {
    if (loginType === AuthType.phone) {
      if (values.verificationCode) {
        await verifyCode(values);
        return;
      }
      await sendVerificationCode(values);
    } else {
      await loginWithEmailAndPassword(values.email, values.password);
    }
  }

  const onChangeLoginType = (event: ChangeEvent<HTMLInputElement>) => {
    const authWay = event.currentTarget.value;
    setLoginType(event.currentTarget.value as AuthType);

    if (authWay === 'phone') {
      mixpanel.track(mixpanelSignInEvents.buttons.togglePhone);
    } else {
      mixpanel.track(mixpanelSignInEvents.buttons.toggleEmail);
    }
  };

  const getErrorMessage = (error) => {
    if (isFirebaseAuthError(error)) {
      return getHumanizedFbError(error, t as TFunction);
    }
    if (error.message) {
      return error.message;
    }
    if (error.response.data) {
      return error.response.data;
    }
  };

  const resendCodeBtnHandler = (e, formikValues: LoginForm) => {
    e.preventDefault();
    e.stopPropagation();

    if (!showConfirm) {
      formik.current.submitForm();
      return;
    }

    if (!counter && !loginInProgress) {
      mixpanel.track(mixpanelSignInEvents.buttons.resendCode);
      sendVerificationCode(formikValues);
    }
  };

  const getEmailFields = () => {
    return (
      <>
        <div className="form-onboarding-z__item">
          <FormControl name="email" label={t('sign_in:fields.email.label', 'Email')}>
            <Field onKeyPress={(e) => handleEnterPress(e, 'loginWithEmailAndPassword')} autoComplete="off">
              {({field}) => (
                <EmailInput
                  placeholder={t('sign_in:fields.email.placeholder', 'Enter email address')}
                  {...field}
                  readOnly={!!emailFromQuery}
                />
              )}
            </Field>
          </FormControl>
        </div>
        <div className="form-onboarding-z__item">
          <FormControl
            name="password"
            label={t('sign_in:fields.password.label', 'Password')}
            iconName={passwordVisibility.visible ? 'visible_outlined' : 'hidden_outlined'}
            onIconClick={passwordVisibility.toggleVisibility}
          >
            <Field
              name="password"
              type={passwordVisibility.visible ? 'text' : 'password'}
              className="ctrl-textfield"
              placeholder={t('sign_in:fields.password.placeholder', 'Enter password')}
              autoComplete="off"
              onKeyPress={(e) => handleEnterPress(e, 'loginWithEmailAndPassword')}
            />
          </FormControl>
        </div>
        <div className="form-onboarding-z__item">
          <div className="form-onboarding-z__forgot">
            <Link
              data-cy="reset_password_link"
              to={routes.forgotPassword}
              onClick={() => mixpanel.track(mixpanelForgotPasswordEvents.buttons.forgotPassword)}
            >
              {t('sign_in:forgot_password', 'Forgot Password?')}
            </Link>
          </div>
        </div>
      </>
    );
  };

  const getPhoneFields = (formikValues: LoginForm) => {
    return (
      <>
        <div className="form-onboarding-z__item">
          <div className="ctrl-phone" ref={phoneFormRef}>
            <div className="ctrl-phone__item ctrl-phone__item--code">
              <FormControl name="countryCode" label={t('sign_in:fields.phone_code.label', 'Mobile Phone Number')}>
                <CountryCodeSelector
                  onChange={setCountryCode}
                  name="countryCode"
                  initialValue={countryCode}
                  width={phoneFormRef.current?.clientWidth}
                  disabled={!!phoneNumberFromQuery}
                />
              </FormControl>
            </div>
            <div className="ctrl-phone__item ctrl-phone__item--number">
              <FormControl
                className="ctrl-form--button-link"
                name="phoneNumber"
                label={t('sign_in:fields.phone_number.label', 'Phone Number')}
              >
                <Field name="phoneNumber">
                  {(props: FieldProps) => (
                    <PhoneInput
                      {...props.field}
                      countryCode={countryCode}
                      className="ctrl-textfield"
                      id="apply_onboarding_phone"
                      placeholder={t('sign_in:fields.phone_number.placeholder', 'Enter Phone Number')}
                      onKeyPress={(e) => handleEnterPress(e, 'sendCode')}
                      disabled={!!phoneNumberFromQuery}
                    />
                  )}
                </Field>
                <button
                  className="ctrl-btn-link ctrl-btn-link--size-m ctrl-form__button-link"
                  type="button"
                  onClick={(e) => resendCodeBtnHandler(e, formikValues)}
                >
                  {!showConfirm
                    ? t('sign_in:buttons.send_code', 'Send')
                    : !counter
                    ? t('sign_in:buttons.resend_code', 'Resend')
                    : `${t('sign_in:buttons.resend_code_in', 'Resend in')} ${counter}s`}
                </button>
              </FormControl>
            </div>
          </div>
        </div>
        {showConfirm && (
          <div className="form-onboarding-z__item">
            <FormControl
              name="verificationCode"
              label={t('sign_in:fields.verification_code.label', 'Enter the code we`ve sent to your number')}
            >
              <Field name="verificationCode">
                {({field}: FieldProps) => (
                  <InputMask
                    className="ctrl-textfield"
                    id="signin_verificationCode"
                    mask="999999"
                    placeholder={t('sign_in:fields.verification_code.placeholder', 'Enter the code')}
                    maskChar={null}
                    disabled={!showConfirm}
                    {...field}
                  >
                    {(inputProps) => <input disabled={!showConfirm} ref={verifyCodeInputRef} {...inputProps} />}
                  </InputMask>
                )}
              </Field>
            </FormControl>
          </div>
        )}
      </>
    );
  };

  const onEnterButtonClickHandler = (e) => {
    e.preventDefault();

    if (loginType === AuthType.phone) {
      mixpanel.track(mixpanelSignInEvents.buttons.phoneEnter);
    } else {
      mixpanel.track(mixpanelSignInEvents.buttons.emailEnter);
    }

    formik.current.submitForm();
  };
  return (
    <>
      <MetaTags
        title={t('sign_in:meta_tags.title', 'C4 Login')}
        description={t('sign_in:meta_tags.description', 'Login to Crews by Core PRO')}
        keywords={META_KEYWORDS}
      />
      <OnboardingLayout>
        <Formik<LoginForm>
          initialValues={{
            phoneNumber: phoneNumberFromQuery ? splitPhoneByCountryCode(phoneNumberFromQuery)[1] : '',
            verificationCode: '',
            email: emailFromQuery || '',
            password: '',
          }}
          validationSchema={validationSchema}
          onSubmit={submit}
          innerRef={formik}
        >
          {(props) => (
            <ZetOnboardingContainer
              title={t('sign_in:form.title', 'Sign in to Crews by Core')}
              description={
                <>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('sign_in:form.description.1', 'Don&apos;t have an account? Sign up with free trial'),
                    }}
                  />{' '}
                  <Link
                    data-cy="sign_up_link"
                    onClick={() => mixpanel.track(mixpanelSignInEvents.buttons.signupHere)}
                    to={routes.getStarted}
                  >
                    {t('sign_in:form.description.2', 'here →')}
                  </Link>
                </>
              }
            >
              <>
                <div className="form-onboarding-z__item">
                  <div className="ctrl-radios">
                    <div className="ctrl-radios__grid">
                      <label className="ctrl-radios__item">
                        <Field
                          className="ctrl-radios__field"
                          name="loginType"
                          type="radio"
                          value="email"
                          checked={loginType === AuthType.email}
                          onChange={onChangeLoginType}
                        />
                        <span className="ctrl-radios__visual"></span>
                        <span className="ctrl-radios__label">{t('sign_in:form.login_types.email', 'Email')}</span>
                      </label>
                      <label className="ctrl-radios__item">
                        <Field
                          className="ctrl-radios__field"
                          name="loginType"
                          type="radio"
                          value="phone"
                          checked={loginType === AuthType.phone}
                          onChange={onChangeLoginType}
                        />
                        <span className="ctrl-radios__visual"></span>
                        <span className="ctrl-radios__label">
                          {t('sign_in:form.login_types.phone', 'Phone Number')}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {loginType === AuthType.phone ? getPhoneFields(props.values) : getEmailFields()}
                <div className="form-onboarding-z__item form-onboarding-z__item--actions">
                  <Button
                    disabled={!props.isValid || (loginType === AuthType.phone && !showConfirm) || loginInProgress}
                    onClick={onEnterButtonClickHandler}
                    data-cy="signin_submit_btn"
                  >
                    {t('sign_in:form.submit', 'Enter')}
                  </Button>
                </div>
                {loginInProgress && <Loader />}
              </>
            </ZetOnboardingContainer>
          )}
        </Formik>
      </OnboardingLayout>
    </>
  );
};

export default SignInPage;

import {ChangeEvent, ReactNode, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import s from 'shared/components/CoreNewUI/Autocomplete/Autocomplete.module.scss';
import {CtrlBtnOption, FormControl} from 'shared/components/CoreNewUI/index';
import {Icon} from 'shared/components/index';

export type SearchResultOptionsType = {
  label: string;
  value: string;
  icon?: ReactNode;
};

type OnSelect<Values> = (fieldName: string, value: Values) => void;
export type Props<IsMulti extends boolean, Values = IsMulti extends true ? string[] : string> = {
  items: SearchResultOptionsType[];
  name: string;
  onSelect: OnSelect<Values>;
  selected: Values;
  disabled?: boolean;
  isMulti?: IsMulti;
  hideSearch?: boolean;
};

const Autocomplete = <IsMulti extends boolean>({
  items,
  name,
  selected,
  onSelect,
  disabled,
  hideSearch = false,
  isMulti,
}: Props<IsMulti>) => {
  const [placeholder, setPlaceholder] = useState<string>('');
  const selectedOptions = useMemo(() => (selected ? [].concat(selected) : []), [selected]);
  const {t} = useTranslation('filters');

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPlaceholder(e.target.value);
  };

  const handleSelect = (values: string[]) => {
    if (isMulti) {
      (onSelect as OnSelect<string[]>)(name, values);
    } else {
      (onSelect as OnSelect<string>)(name, values[0] ?? '');
    }
  };

  const clear = () => {
    if (placeholder || selectedOptions.length) {
      setPlaceholder('');
      handleSelect([]);
    }
  };

  const searchResults = useMemo(() => {
    if (placeholder) {
      return items.filter((o) => !!o.label && o.label.toLowerCase().includes(placeholder.toLowerCase()));
    }
    return items;
  }, [selectedOptions, items, placeholder]);

  const onClickOption = (selectedOption: string) => {
    const isCheckedOption = !!selectedOptions.find((option) => option === selectedOption);
    let result: string[];
    if (isCheckedOption) {
      result = selectedOptions.filter((o) => o !== selectedOption);
    } else {
      result = isMulti ? [...selectedOptions, selectedOption] : [selectedOption];
    }
    handleSelect(result);
  };

  return (
    <>
      {!hideSearch && (
        <FormControl name={name} icon={<Icon colorFill name={placeholder ? 'clear' : 'search_2'} onClick={clear} />}>
          <input
            type="text"
            value={placeholder}
            onChange={onInputChange}
            placeholder={t('form.autocomplete.placeholder', 'Search...')}
            disabled={disabled}
          />
        </FormControl>
      )}
      <div className={s.autoCompleteResults}>
        {searchResults?.length
          ? searchResults.map((option, index) => {
              return (
                <CtrlBtnOption
                  icon={option.icon ? option.icon : null}
                  selected={selectedOptions?.includes(option.value)}
                  key={`${option.value}_${index}`}
                  title={option.label}
                  onClick={() => onClickOption(option.value)}
                />
              );
            })
          : (!selected || !selected?.length) && (
              <div className={s.autoCompleteResults__noResult}>
                {t('form.autocomplete.no_results', 'No results found.')}
              </div>
            )}
      </div>
    </>
  );
};
export default Autocomplete;

import {createEntityAdapter, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {TaskImportObject} from 'shared/components/TasksImport/utils/types';
import {safeParseFromLocalStorage} from 'shared/helpers/ls';
import {ActivitiesIdRowMap} from 'shared/models/project';
import {TaskDetailsModelDTO} from 'shared/models/task';
import {TaskDependencyDto} from 'shared/models/TaskDependency';
import {RootState} from 'store';
import {logoutUser} from 'store/actions';

import {createTask, deleteTask, getTask, updateAppliedBaselineDate, updateTask} from './actions';

const SHOW_BASE_LINE_KEY = 'ganttView:showBaseLine';

const tasksAdapter = createEntityAdapter<TaskDetailsModelDTO>();

function getInitialState() {
  return tasksAdapter.getInitialState({
    baseline: {
      appliedBaselineDate: null,
    },
    currentTask: null as TaskDetailsModelDTO,
    showBaseLineLayer: safeParseFromLocalStorage(SHOW_BASE_LINE_KEY) || false,
    importedTasks: [],
    rownumberMap: {} as ActivitiesIdRowMap,
    tasksDependencies: [] as TaskDependencyDto[],
  });
}

const slice = createSlice({
  initialState: getInitialState(),
  name: 'tasks',
  reducers: {
    setTask: (state, action: PayloadAction<TaskDetailsModelDTO>) => {
      state.currentTask = action.payload;
    },
    updateTask: (state, action: PayloadAction<Partial<TaskDetailsModelDTO>>) => {
      state.currentTask = {...state.currentTask, ...action.payload};
    },
    toggleBaseLineLayer: (state) => {
      state.showBaseLineLayer = !state.showBaseLineLayer;
      localStorage.setItem(SHOW_BASE_LINE_KEY, JSON.stringify(state.showBaseLineLayer));
    },
    setImportedTasks: (state, action: PayloadAction<TaskImportObject['task'][]>) => {
      state.importedTasks = action.payload;
    },
    setRowNumberMap: (state, action: PayloadAction<ActivitiesIdRowMap>) => {
      state.rownumberMap = action.payload;
    },
    setTasksDependencies: (state, action: PayloadAction<TaskDependencyDto[]>) => {
      state.tasksDependencies = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateAppliedBaselineDate, (state, action) => {
      state.baseline.appliedBaselineDate = action.payload.appliedBaselineDate;
    });
    builder.addCase(getTask.fulfilled, (state, action) => {
      state.currentTask = action.payload;
    });
    builder.addCase(updateTask.fulfilled, (state, action) => {
      if (action.payload) {
        if (state.entities[action.payload.id]) {
          tasksAdapter.upsertOne(state, action.payload);
        }
        if (state.currentTask?.id === action.payload.id) {
          Object.assign(state.currentTask, action.payload);
        }
      }
    });
    builder.addCase(createTask.fulfilled, (state, action) => {
      state.currentTask = action.payload;
    });
    builder.addCase(deleteTask.fulfilled, (state, action) => {
      state.currentTask = null;
      tasksAdapter.removeOne(state, action.meta.arg);
    });
    builder.addCase(logoutUser, () => getInitialState());
  },
});

export default slice.reducer;
export const {
  selectById: selectTaskById,
  selectEntities: selectAllTasksEntities,
  selectAll: selectAllTasks,
} = tasksAdapter.getSelectors<RootState>((state) => state.tasks);
export const taskActions = slice.actions;

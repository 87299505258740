import {createAction, createAsyncThunk} from '@reduxjs/toolkit';

import {TasksApi} from 'api';
import {getTaskChangedFieldsOnly, isChangedTaskAssigneesList, replaceOwnerWithAssignee} from 'shared/helpers/task';
import {TaskAssignees, TaskDetailsModelDTO} from 'shared/models/task';
import {serializeError} from 'store/utils/serializeError';

type UpdateOrCreateTaskPayload = {
  model: TaskDetailsModelDTO;
  currentTask?: TaskDetailsModelDTO;
  membersToAdd?: TaskAssignees[];
  members?: TaskAssignees[];
  isScopedUpdate?: boolean;
};

const addTaskAssignees = async (
  taskId: string,
  {membersToAdd}: Pick<UpdateOrCreateTaskPayload, 'membersToAdd'> = {},
) => {
  const promises = [];
  if (membersToAdd?.length) {
    membersToAdd.forEach((member) => {
      promises.push(TasksApi.addAssignee(taskId, member));
    });
    await Promise.all<TaskDetailsModelDTO[]>(promises);
  }
  if (!promises.length) return;
  const actual = (await promises.pop()) as TaskDetailsModelDTO;
  return actual.assignees;
};

export const updateTaskAssignees = async (
  taskId: string,
  {members}: Pick<UpdateOrCreateTaskPayload, 'members'> = {},
) => {
  return TasksApi.updateAssignee(taskId, members);
};

export const getTask = createAsyncThunk(
  '/tasks/getTask',
  (taskId: string) => {
    return TasksApi.getTask(taskId);
  },
  {serializeError},
);

export const updateTask = createAsyncThunk(
  '/tasks/updateTask',
  async ({model, currentTask, members, isScopedUpdate}: UpdateOrCreateTaskPayload, {rejectWithValue}) => {
    const changedFields = getTaskChangedFieldsOnly(currentTask, model);
    const hasChangedFields = !!Object.keys(changedFields).length;
    if (isChangedTaskAssigneesList(replaceOwnerWithAssignee(currentTask.assignees), [...Object.values(members)])) {
      const res = await updateTaskAssignees(currentTask.id, {members});
      if (!hasChangedFields) return res;
    }
    try {
      if (hasChangedFields) {
        return await TasksApi.updateTask(getTaskChangedFieldsOnly(currentTask, model), isScopedUpdate);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {serializeError},
);

export const createTask = createAsyncThunk(
  '/tasks/createTask',
  async ({model, ...members}: UpdateOrCreateTaskPayload, api) => {
    try {
      const res = await TasksApi.createTask(model);
      const newAssignees = await addTaskAssignees(res.id, members);
      return newAssignees.length
        ? ({...res, assignees: newAssignees, assignmentCount: newAssignees.length} as TaskDetailsModelDTO)
        : res;
    } catch (error) {
      return api.rejectWithValue(error);
    }
  },
  {serializeError},
);

export const deleteTask = createAsyncThunk(
  '/tasks/delete',
  async (taskId: string) => {
    return TasksApi.deleteTask(taskId);
  },
  {serializeError},
);

export const updateAppliedBaselineDate = createAction<{appliedBaselineDate: string}>(
  '/tasks/updateAppliedBaselineDate',
);

export const restoreTask = createAsyncThunk('/tasks/restore', async (taskId: string) => {
  return TasksApi.restoreTask(taskId);
});

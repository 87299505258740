import {nanoid} from 'nanoid';
import React, {ComponentProps, FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import Select, {components} from 'react-select';

import {ProjectsApi} from 'api';
import {OptionType, SkeletonFieldPreloader} from 'shared/components';
import {useEffectWithCompanyId} from 'shared/hooks';
import {usePrevious} from 'shared/hooks/core';
import {CompanyOrgs} from 'shared/models/company';

import {defaultReactSelectStyles} from '../styles';

type Props = {
  name?: string;
  placeholder?: string;
  value: string[];
  loadingPlaceholder?: string;
  onChange?: (value: string) => void;
  isMulti?: boolean;
  isDisabled?: boolean;
  options?: OptionType[];
};

const Option: FC<ComponentProps<typeof components.Option>> = (props) => {
  return (
    <components.Option
      {...props}
      className={props.cx(
        {
          option: true,
          'option--is-disabled': props.isDisabled,
          'option--is-focused': props.isFocused,
          'option--is-selected': props.isSelected,
          'option--is-create-new': props.data?.__isNew__,
        },
        props.className,
      )}
    />
  );
};

const AsyncCompanyOrgsSelect: FC<Props & ComponentProps<typeof Select>> = ({
  name,
  placeholder,
  loadingPlaceholder = 'Loading...',
  value,
  onChange,
  isMulti,
  isDisabled,
  options,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [localOptions, setLocalOptions] = useState<OptionType[]>(null);
  const {projectId} = useParams<{projectId: string}>();
  const prevProjectId = usePrevious(projectId);
  const {t} = useTranslation('common');

  const selected = useMemo(() => {
    const getSelected = (values: string[], options: OptionType[]) => {
      return (
        values?.map<OptionType>((org) => {
          const selectedOption = options.find((o) => o.id === org);
          return selectedOption ? {...selectedOption} : {value: org, label: org, id: nanoid()};
        }) || []
      );
    };
    const collectionToSearch = options || localOptions || [];
    const selectedOptions = getSelected([].concat(value), collectionToSearch);
    return isMulti ? selectedOptions : selectedOptions[0];
  }, [value, localOptions, isMulti, options]);

  const fetchOptions = useCallback(() => {
    if (options) return;
    if (!isLoading && projectId) {
      setIsLoading(true);
      ProjectsApi.getOrgs(projectId)
        .then((orgs: CompanyOrgs[]) => {
          setLocalOptions(
            orgs.map((org) => ({value: org.group.name, label: org.group.name, id: org.id} as OptionType)),
          );
        })
        .catch(() => {
          setLocalOptions([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isLoading]);

  useEffectWithCompanyId(fetchOptions);

  useEffect(() => {
    if (prevProjectId && prevProjectId !== projectId) {
      fetchOptions();
    }
  }, [projectId, prevProjectId, fetchOptions]);

  const onSelectChange = useCallback(
    (option) => {
      const optionArr = options ? option?.map((o) => o.id) : option?.map((o) => o.value);
      onChange(isMulti ? optionArr || [] : option?.value);
    },
    [onChange, isMulti],
  );

  return (
    <SkeletonFieldPreloader when={!projectId}>
      <Select
        {...{
          name,
          isMulti,
          isSearchable: true,
          isClearable: true,
          isDisabled: isLoading || isDisabled,
          isLoading: isLoading,
          options: options || localOptions,
          placeholder: isLoading
            ? loadingPlaceholder
            : placeholder || t('dropdown.org_placeholder', 'Select Organization'),
          className: 'react-select',
          classNamePrefix: 'react-select',
          onChange: onSelectChange,
          styles: defaultReactSelectStyles,
          value: value ? selected : null,
          components: {Option},
          isValidNewOption: (val) => !!val.trim(),
          noOptionsMessage: () => t('dropdown.no_options', 'No options'),
        }}
      />
    </SkeletonFieldPreloader>
  );
};

export default AsyncCompanyOrgsSelect;

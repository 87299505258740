import {ObserverAction, ObserverActionSource} from 'services/TasksObserver/const';
import {TaskDetailsModelDTO, TaskProjection} from 'shared/models/task';

export function isLoadEvent(e: unknown): e is ObserverLoadEvent {
  return e && typeof e === 'object' && 'ids' in e;
}

export type ObserverLoadEvent = {
  ids: string[];
} & ObserverBaseEvent;

export type ObserverData = TaskDetailsModelDTO[];

export type ObserverUpdateEvent = {
  projection: TaskProjection;
  data?: Partial<TaskDetailsModelDTO>;
} & ObserverBaseEvent;

type ObserverBaseEvent = {
  action?: ObserverAction;
  projectId: string;
  source?: ObserverActionSource;
  sourceName?: string;
  refetchRowNumbers?: boolean;
  meta?: {
    create?: {
      isPristine: boolean;
      index: number;
    };
    move?: {
      parent: string;
      index: number;
    };
  };
};

export type ObserverEvent = ObserverLoadEvent | ObserverUpdateEvent;

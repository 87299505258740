import * as Sentry from '@sentry/react';
import {toast} from 'react-toastify';

import {stringifyAxiosError} from 'shared/helpers/axios';
import {TaskDetailsModelDTO} from 'shared/models/task';

enum QueueState {
  Pending,
  Processing,
}

const DELAY_MS = 1500;

type TaskChainJobType = 'create' | 'update' | 'extra';
type TaskID = string | number;
type TaskChainJobPayload = TaskID | {id: TaskID; [key: string]: any};
type TaskChainJobCallback = (TaskChainJobPayload) => Promise<TaskChainJobPayload>;
type TaskChainJob = {
  task?: TaskDetailsModelDTO;
  callback: TaskChainJobCallback;
  type: TaskChainJobType;
};
type TaskChainGroupedJobs = {
  runAt: number;
  create: TaskChainJob;
  update: TaskChainJob[];
  extra: TaskChainJob[];
};
type JobSubscriberCallback = (result: TaskChainJobPayload) => unknown;

class TaskChainUpdateQueue {
  private static instance: TaskChainUpdateQueue;
  private queue: Map<TaskID, TaskChainGroupedJobs> = new Map();

  private subscribers: Map<TaskID, JobSubscriberCallback[]> = new Map();

  private state: QueueState = QueueState.Pending;

  private interval: ReturnType<typeof setTimeout>;

  private constructor() {
    this.initQueue();
  }

  static getInstance() {
    if (!TaskChainUpdateQueue.instance) {
      TaskChainUpdateQueue.instance = new TaskChainUpdateQueue();
    }
    return TaskChainUpdateQueue.instance;
  }

  public dispatch(taskId: string, callback: TaskChainJobCallback, type: TaskChainJobType = 'update') {
    const job = {callback, type};
    const runAt = +new Date() + DELAY_MS;
    if (!this.queue.has(taskId)) {
      this.queue.set(taskId, {
        create: null,
        update: [],
        extra: [],
        runAt: runAt,
      });
    }
    const taskJobs = this.queue.get(taskId);
    taskJobs.runAt = runAt;
    if (type === 'create') {
      taskJobs.create = job;
    } else {
      taskJobs[type] = taskJobs[type].concat(job);
    }
  }

  private initQueue() {
    if (!this.interval) {
      const enqueue = async () => {
        if (this.queue.size >= 1 && this.state === QueueState.Pending) {
          await this.runJobs();
          this.state = QueueState.Pending;
        }
        setTimeout(enqueue, DELAY_MS);
      };
      this.interval = setTimeout(enqueue, DELAY_MS);
    }
  }

  private notifyOnJobComplete(taskId: TaskID, result: TaskChainJobPayload) {
    if (this.subscribers.has(taskId)) {
      const subscribers: JobSubscriberCallback[] = this.subscribers.get(taskId);
      while (subscribers.length > 0) {
        const cb = subscribers.shift();
        cb(result);
      }
    }
    return null;
  }

  public subscribe(taskId: string, cb: JobSubscriberCallback) {
    if (this.subscribers.has(taskId)) {
      const current = this.subscribers.get(taskId);
      this.subscribers.set(taskId, current.concat([cb]));
    } else {
      this.subscribers.set(taskId, [cb]);
    }
  }

  private runJobs = async () => {
    const now = +new Date();
    this.state = QueueState.Processing;
    for (const [taskId, grouped] of this.queue.entries()) {
      if (now >= grouped.runAt) {
        await this.runGroupedJobs(taskId, grouped);
      }
    }

    return Promise.resolve(true);
  };

  private runGroupedJobs = async (taskId: string | number, jobs: TaskChainGroupedJobs) => {
    this.queue.delete(taskId);
    const runCallbacks = (jobs: TaskChainJob[], payload: TaskChainJobPayload) => {
      return Promise.all(jobs.map((job) => job.callback(payload)));
    };

    try {
      if (jobs.create) {
        // ignore update tasks, because create will save all data
        await jobs.create.callback(taskId).then((modelOrId) => {
          // remap current pending request to new id
          if (this.queue.has(taskId)) {
            this.queue.set(typeof modelOrId === 'object' ? modelOrId.id : modelOrId, this.queue.get(taskId));
            this.queue.delete(taskId);
          }
          this.notifyOnJobComplete(taskId, modelOrId);
          return runCallbacks(jobs.extra, typeof modelOrId === 'object' ? modelOrId.id : modelOrId);
        });
      } else {
        if (jobs.update.length) {
          await jobs.update.pop().callback(taskId);
        }
        await runCallbacks(jobs.extra, taskId);
      }
    } catch (err) {
      Sentry.captureException(err);
      toast.error(stringifyAxiosError(err));
    }
  };

  public findJobByTaskId = (taskId): TaskChainGroupedJobs => {
    if (this.queue.has(taskId)) {
      return this.queue.get(taskId);
    }
    return null;
  };
}
const instance = TaskChainUpdateQueue.getInstance();
export default instance;

import {inject, injectable} from 'inversify';
import {IObservableArray, makeObservable, observable, runInAction} from 'mobx';

import {TasksApi, ProjectsApi, FeedbackService} from 'api';
import {SortOrder} from 'shared/constants/common';
import {extractAxiosError, fetchAllWithGenerator, fetchManager} from 'shared/helpers/axios';
import {MessageTypeMap} from 'shared/models/feedback';
import {IOC_TYPES} from 'shared/models/ioc';
import {
  TaskModelRawDTO,
  TaskProjection,
  TaskObjectType,
  TaskFilterQuery,
  TaskStatusQuery,
  SortField,
  FeedbackProjectModelDTO,
} from 'shared/models/task';
import {TaskDependencyDto} from 'shared/models/TaskDependency';

import type {UIStoreType} from './UIStore';

export function nullsFirstOSKSort(a: TaskModelRawDTO, b: TaskModelRawDTO) {
  if (a.outline_sort_key === b.outline_sort_key) {
    return 0;
  }
  if (!a.outline_sort_key) {
    return -1;
  } else if (!b.outline_sort_key) {
    return 1;
  } else {
    return a.outline_sort_key.localeCompare(b.outline_sort_key);
  }
}

export type TasksStoreType = {
  tasks: TaskModelRawDTO[];
  dependencies: TaskDependencyDto[];
  isLoading: boolean;
  projectId: string | null;
  tasksLoaded: boolean;
  error: unknown | null;
  silent: boolean;
  projectFeedback: FeedbackProjectModelDTO[];

  setTasks: (tasks: TaskModelRawDTO[]) => void;
  getSortedTasksCopy: () => TaskModelRawDTO[];
  getTaskById: (taskId: string) => TaskModelRawDTO;
  setError: (error: unknown) => void;
  setDependencies: (dependencies: TaskDependencyDto[]) => void;
  setIsLoading: (loading: boolean) => void;
  setTasksLoaded: (loaded: boolean) => void;
  setProjectId: (projectId: string) => Promise<void>;
  addTasks: (addedTasks: TaskModelRawDTO[]) => void;
  updateTasks: (tasks: Partial<TaskModelRawDTO>[]) => void;
  changeTaskId: (oldId: number, newId: string) => void;
  removeTasks: (taskIds: string[]) => void;
  loadTasks: () => Promise<void>;
  setLoadDeleted: (loadDeleted: boolean) => Promise<void>;
  addProjectFeedback: (newFeedback: FeedbackProjectModelDTO[]) => void;
  deleteProjectFeedback: (id: string) => void;
};

@injectable()
export class TasksStore implements TasksStoreType {
  @inject(IOC_TYPES.UIStore) private uiStore: UIStoreType;

  tasks: TaskModelRawDTO[] = [];
  dependencies: TaskDependencyDto[] = [];
  isLoading = false;
  projectId: string | null = null;
  tasksLoaded = false;
  error = null;
  loadDeleted = false;
  silent = false;
  projectFeedback: FeedbackProjectModelDTO[] = [];

  constructor() {
    // eslint-disable-next-line mobx/exhaustive-make-observable
    makeObservable(this, {
      tasks: observable.shallow,
      dependencies: observable.shallow,
      projectFeedback: observable.shallow,
      isLoading: observable,
      projectId: observable,
      tasksLoaded: observable,
      error: observable,
      loadDeleted: observable,
      silent: observable,
    });
  }

  async setLoadDeleted(loadDeleted: boolean) {
    if (this.loadDeleted !== loadDeleted) {
      this.loadDeleted = loadDeleted;
      return this.loadTasks();
    }
  }

  setTasks(tasks: TaskModelRawDTO[]) {
    tasks.sort(nullsFirstOSKSort);
    (this.tasks as IObservableArray).replace(tasks);
  }

  getSortedTasksCopy() {
    return JSON.parse(JSON.stringify(this.tasks)).sort(nullsFirstOSKSort);
  }

  setError(error: unknown) {
    this.error = error;
  }

  setDependencies(dependencies: TaskDependencyDto[]) {
    (this.dependencies as IObservableArray).replace(dependencies);
  }

  setIsLoading(loading: boolean) {
    this.isLoading = loading;
    this.uiStore.setLoading(loading);
  }

  setTasksLoaded(loaded: boolean) {
    this.tasksLoaded = loaded;
  }

  getTaskById(taskId: string) {
    return this.tasks.find((task) => task.id === taskId);
  }

  async setProjectId(projectId: string) {
    if (this.projectId !== projectId || !this.tasks.length) {
      this.projectId = projectId;
      await this.loadTasks();
    }
  }

  addTasks(addedTasks: TaskModelRawDTO[]) {
    addedTasks.sort(nullsFirstOSKSort);
    if (this.tasks.length) {
      for (const task of addedTasks) {
        // this.tasks is an array sorted by outline_sort_key that does not contain task.
        // Find the correct offset to insert task into this.tasks
        // The most common case where the new tasks outline sort key already exists is when
        // a subsequent updateTasks is anticipated that will move those out of the way, so
        // prefer inserting the newest task before any task with matching OSK, so >=.
        const offset = this.tasks.findIndex((t) => t.outline_sort_key >= task.outline_sort_key);
        if (offset === -1) {
          this.tasks.push(task);
        } else {
          this.tasks.splice(offset, 0, task);
        }
      }
    } else {
      this.tasks.push(...addedTasks);
    }
    this.setCommentCountForTasks(this.tasks);
  }

  updateTasks(tasks: Partial<TaskModelRawDTO>[]) {
    // Find the task in the store matching the provided tasks and overwrite the attributes
    const updateMap = new Map(tasks.map((task) => [task.id, task]));
    // This double-pass looks lame, but it's getting around the fact that observe doesn't
    // support transactions.  Also, it isn't actually much more costly.
    for (let i = 0; i < this.tasks.length; i++) {
      if (updateMap.has(this.tasks[i].id)) {
        Object.assign(this.tasks[i], updateMap.get(this.tasks[i].id));
      }
    }
    this.silent = true;
    this.tasks.sort(nullsFirstOSKSort);
    this.silent = false;

    for (let i = 0; i < this.tasks.length; i++) {
      if (updateMap.has(this.tasks[i].id)) {
        // Object.assign, which would seem more natural here, doesn't fire the listener
        // Also, no need to be granular, I'm going to transform the entire task anyway
        this.tasks[i] = {...this.tasks[i]};
      }
    }
  }

  changeTaskId(oldId: number, newId: string) {
    const task = this.tasks.find((task: TaskModelRawDTO) => typeof task.id === 'number' && task.id === oldId);
    if (task) {
      task.id = newId;
    }
  }

  removeTasks(taskIds: string[]) {
    const taskIdsSet = new Set(taskIds);
    runInAction(() => {
      let startIdx = 0;
      let endIdx = 0;

      // This reduces calls to ganttStore.taskListChanged by removing tasks in ranges.
      while (endIdx < this.tasks.length) {
        if (taskIdsSet.has(this.tasks[endIdx].id)) {
          endIdx++;
        } else {
          // If the task ID isn't on set of IDs and our startIdx is different to endIdx, it removes the tasks in that range.
          // After that, we reset endIdx to startIdx to start a new range.
          if (startIdx !== endIdx) {
            this.tasks.splice(startIdx, endIdx - startIdx);
            endIdx = startIdx;
          } else {
            // If startIdx equals endIdx, increment both.
            startIdx++;
            endIdx++;
          }
        }
      }

      // If loop exited with startIdx !== endIdx, it removes remaining tasks in that range.
      if (startIdx !== endIdx) {
        this.tasks.splice(startIdx, endIdx - startIdx);
      }
    });
  }

  addProjectFeedback(newFeedback: FeedbackProjectModelDTO[]) {
    this.projectFeedback = (this.projectFeedback || []).concat(newFeedback);
  }

  deleteProjectFeedback(id: string) {
    const feedbackIndex = this.projectFeedback.findIndex((feedback) => feedback.id === id);
    if (feedbackIndex !== -1) {
      this.projectFeedback.splice(feedbackIndex, 1);
    }
  }

  private async loadProjectFeedback() {
    const feedback = await FeedbackService.getAllFeedBackForProject(this.projectId);
    this.projectFeedback = feedback;
  }

  private setCommentCountForTasks(results: TaskModelRawDTO[]) {
    const feedbackGroupedByTask: Record<string, Record<number, boolean>> = this.projectFeedback?.reduce((acc, fb) => {
      const baseId = fb.baseId;
      if (!acc[fb.taskId]) {
        acc[fb.taskId] = {};
      }
      if (fb.feedbackType === MessageTypeMap.message || fb.feedbackType === MessageTypeMap.image) {
        // Mark true if there's at least one message or image feedback for this baseId
        acc[fb.taskId][baseId] = true;
      }
      return acc;
    }, {});

    for (let i = 0; i < results.length; i++) {
      const taskId = results[i].id;
      const feedbackByBaseId = feedbackGroupedByTask?.[taskId];
      const count = feedbackByBaseId
        ? Object.values(feedbackByBaseId).reduce((acc, hasFeedback) => acc + (hasFeedback ? 1 : 0), 0)
        : '';
      results[i].comment_count = count;
    }
    return results;
  }

  async loadTasks() {
    try {
      this.setIsLoading(true);
      this.setError(null);
      this.setDependencies([]);
      this.setTasks([]);
      this.setTasksLoaded(false);

      const results: TaskModelRawDTO[] = await fetchAllWithGenerator<TaskModelRawDTO>(
        {
          request: async (offset: number, take: number) => {
            const filterParams: Partial<TaskFilterQuery & TaskStatusQuery> = {
              objectTypeList: [TaskObjectType.activity, TaskObjectType.task, TaskObjectType.summary],
              projectId: this.projectId,
            };
            if (this.loadDeleted) {
              filterParams.deleted = true;
            }

            const response = await TasksApi.getProjectTasks({
              projection: TaskProjection.task,
              offset: offset,
              limit: take,
              sortField: SortField.outlineSortKey,
              sortOrder: SortOrder.ASC,
              params: filterParams,
            });

            return {
              ...response,
              data: response.data as unknown as TaskModelRawDTO[],
            };
          },
          initialTake: 500,
          maxTake: 2000,
          maxRetries: 3,
          retryDelay: 2000,
          initialParallelCalls: 7,
          maxParallelCalls: 9,
        },
        fetchManager,
      );

      const res = await ProjectsApi.getTaskDependencies(this.projectId, []);
      await this.loadProjectFeedback();
      const resultsWithCommentCount = this.setCommentCountForTasks(results);

      runInAction(() => {
        this.setTasks(resultsWithCommentCount);
        this.setDependencies(res.dependencies);
        this.setIsLoading(false);
        this.setTasksLoaded(true);
      });
    } catch (error) {
      console.error('Error in loadTasks:', error);
      extractAxiosError(error);
      runInAction(() => {
        this.setError(error);
        this.setIsLoading(false);
      });
    }
  }
}

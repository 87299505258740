import cn from 'classnames';
import React, {forwardRef, InputHTMLAttributes, LegacyRef, useCallback, useRef} from 'react';
import ReactDatePicker, {ReactDatePickerProps} from 'react-datepicker';

import Icon from 'shared/components/Icon';
import {KEYCODE} from 'shared/constants/common';
import {useKey} from 'shared/hooks/useKey';

import './style.scss';

interface DatePickerCustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  showIcon?: boolean;
  selected?: Date;
  isClearable?: boolean;
  inputStyle?: React.CSSProperties;
  closeByTab: (e: React.KeyboardEvent) => void;
  icon?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const DatePickerCustomInput = forwardRef<HTMLInputElement, DatePickerCustomInputProps>(
  (
    {showIcon = true, selected, isClearable, inputStyle, className, closeByTab, icon = 'calendar-month', ...rest},
    ref,
  ) => {
    const onKeyDown = (e) => {
      closeByTab(e);
      rest.onKeyDown?.(e);
    };
    return (
      <>
        <input
          ref={ref}
          type="text"
          {...rest}
          autoComplete="off"
          className={cn('ctrl-textfield', className)}
          onKeyDown={onKeyDown}
          style={inputStyle}
        />
        {(!selected || !isClearable) && showIcon && (
          <button
            className="ctrl-btn-clear ctrl-form__button"
            type="button"
            onClick={rest.onClick}
            disabled={rest.disabled}
          >
            <span className="ctrl-btn-clear__text">Toggle Calendar</span>
            <Icon name={icon} colorFill size={24} className="ctrl-btn-clear__icon" />
          </button>
        )}
      </>
    );
  },
);
DatePickerCustomInput.displayName = 'DatePickerCustomInput';

const popperModifiers = {
  offset: {
    enabled: true,
    offset: '10, 10',
  },
};

export type DatePickerProps = {
  inputStyle?: React.CSSProperties;
  showIcon?: boolean;
  ref?: LegacyRef<ReactDatePicker>;
  icon?: string;
};

const DatePicker = forwardRef<ReactDatePicker, DatePickerProps & ReactDatePickerProps>(({...props}, ref) => {
  const datepickerRef = useRef<ReactDatePicker>();

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === KEYCODE.TAB) {
        datepickerRef.current.setOpen(false);
      }
    },
    [ref],
  );

  // Close the form on ESC key press
  useKey({
    condition: Boolean(datepickerRef.current),
    key: KEYCODE.ESC,
    actionFn: () => datepickerRef.current.setOpen(false),
  });

  return (
    <ReactDatePicker
      {...props}
      ref={ref || datepickerRef}
      popperModifiers={popperModifiers}
      portalId="date-picker-portal"
      customInput={
        <DatePickerCustomInput
          showIcon={props.showIcon}
          isClearable={props.isClearable}
          selected={props.selected}
          disabled={props.disabled}
          inputStyle={props.inputStyle || {}}
          className={props.className}
          closeByTab={onKeyDown}
          icon={props.icon}
        />
      }
    />
  );
});

export default DatePicker;
DatePicker.displayName = 'DatePicker';

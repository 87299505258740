import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import {Icon, Tooltip} from 'shared/components';
import {IconsMap} from 'shared/constants/icons';
import {getWBSText} from 'shared/helpers/reports';
import {TaskDetailsModelDTO} from 'shared/models/task';

import {DailiesDisplayMode} from '../types';

export const ReportType = {
  dailyLabor: 'dailyLabor',
  completionAmount: 'completionAmount',
} as const;

const DISPLAY_MODE_HEADER = {
  default: DefaultHeader,
  minimal: MinimalHeader,
};

function MinimalHeader({name, handleOpenProgressReport}) {
  const {t} = useTranslation('dailies');
  return (
    <div className="minimal-header">
      <h3 className="dailies-card-header__title">{name}</h3>
      <Tooltip text={t('dailies_report.add_report')} placement="right">
        <button className="daily-report-card__report-cta" onClick={handleOpenProgressReport}>
          <Icon name={IconsMap.document_text_outlined} />
        </button>
      </Tooltip>
    </div>
  );
}

function DefaultHeader({name}) {
  return <h3 className="dailies-card-header__title">{name}</h3>;
}

export function DailiesCardHeader({
  companyName = null,
  displayMode,
  endDate,
  handleOpenProgressReport,
  name,
  oskMap,
  outlineSortKey,
  startDate,
}: {
  companyName: string;
  displayMode?: DailiesDisplayMode;
  endDate: string;
  handleOpenProgressReport?: () => void;
  name: string;
  oskMap: {[osk: string]: TaskDetailsModelDTO};
  outlineSortKey: string;
  startDate: string;
}) {
  const {t} = useTranslation('dailies');
  const wbs = getWBSText(outlineSortKey, oskMap);
  const Heading = DISPLAY_MODE_HEADER[displayMode] ?? DefaultHeader;

  return (
    <div className={cn('dailies-card-header', displayMode)}>
      <div>
        <Heading handleOpenProgressReport={handleOpenProgressReport} name={name} />
        <div className="daily-report-card__report-timeframe">
          <span className="daily-report-card__report-timeframe--date">
            {startDate} → {endDate}
          </span>
        </div>
      </div>
      <div>
        <div className="daily-report-card__report-metrics-wbs">
          {t('dailies_report.wbs')} <span className="daily-report-card__report-metrics-wbs--label">{wbs}</span>
        </div>
        <p className="dailies-card-header__subtitle">{companyName}</p>
      </div>
    </div>
  );
}

import classnames from 'classnames';
import dayjs from 'dayjs';
import {GanttEventName, GanttStatic} from 'dhtmlx-gantt';
import {camelize, camelizeKeys} from 'humps';
import {TFunction} from 'i18next';

import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {debounce} from 'shared/helpers/debounce';
import {useMixpanel} from 'shared/hooks/analytics/useMixpanel';
import {ProjectModel} from 'shared/models/project';
import {RedoType, TaskGanttModel, TaskObjectSubType, TaskObjectType, TaskStatus} from 'shared/models/task';
import {TaskDependencyTypeAbbr, TaskDependencyTypeByAbbr} from 'shared/models/TaskDependency';

import {updateTaskCustomFieldValue} from '../components/Editors/helpers';

import {GANTT_COLUMNS_NAMES, NOT_EDITABLE_COLUMNS, PERSONAL_PROJECT_NAME} from './constants';
import {EventStore} from './eventStore';

export type InlineEditorEventName =
  | 'onContextMenuCustom'
  | 'afterTasksImport'
  | 'onAfterManualUpdate'
  | 'onEditEnd'
  | 'onBeforeEditStart'
  | 'onBeforeSave'
  | 'onEditStart'
  | 'onBeforeAdd';

export type GanttEventNameUnion =
  | GanttEventName
  | 'onTaskOpen'
  | 'onContextMenuCustom'
  | 'afterTasksImport'
  | 'onAfterManualUpdate'
  | 'onCopy'
  | 'bulkDelete'
  | 'focusAnnot'
  | 'DataLoaded'
  | 'selectAll'
  | 'deselectAll'
  | 'toolbarAction';

export type GanttGridEventNames =
  | 'onAfterColumnReorder'
  | 'onBeforeColumnReorder'
  | 'onBeforeColumnDragStart'
  | 'onColumnDragMove';

export type KeyNavigationEventName = 'onKeyDown' | 'onEmptyClick' | 'onTaskClick' | 'onBeforeFocus' | 'onFocus';

type OptionalID = unknown & {value?: {id?: string | number}};

export const FOURTH_LEVEL = 3;

export type OSKMap = Map<string, OSKMapTaskInfo>;
interface OSKMapTaskInfo {
  id: string;
  children_count: number;
  parent: string;
}

const columnsForTrackingMixpanel = [
  GANTT_COLUMNS_NAMES.name,
  GANTT_COLUMNS_NAMES.description,
  GANTT_COLUMNS_NAMES.trackNumber,
];

export function traverseTasks(tasks: {outline_sort_key?: string; id: string}[], map?: OSKMap): OSKMap {
  return tasks.reduce((acc, task) => {
    let parentKey: string;
    if (typeof task.outline_sort_key === 'string' && task.outline_sort_key.length > 10) {
      parentKey = task.outline_sort_key.slice(0, -11);
      if (acc.has(parentKey)) {
        const parent = acc.get(parentKey);
        parent.children_count += 1;
      } else {
        acc.set(parentKey, {id: undefined, children_count: 1, parent: undefined});
      }
    }
    if (acc.has(task.outline_sort_key)) {
      acc.get(task.outline_sort_key).id = task.id;
    } else {
      acc.set(task.outline_sort_key, {id: task.id, children_count: 0, parent: parentKey});
    }
    return acc;
  }, map || (new Map() as OSKMap));
}

export const markWbsDatesDisabled = (task, node) => {
  if (task.object_type === TaskObjectType.summary) {
    node.classList.add('disabled');
  }
};

export function checkEmptyPersonalProject(project: ProjectModel) {
  return project.name === PERSONAL_PROJECT_NAME && project.taskCount === 0;
}

export const disableReadonlyAfterRedo = (actions: {commands: Array<RedoType>}, gantt: GanttStatic) => {
  actions?.commands.forEach((action) => {
    if (action.entity === 'task' && action.type === 'add') {
      if (gantt.isTaskExists((action as OptionalID)?.value?.id)) {
        const task = gantt.getTask((action as OptionalID)?.value?.id);
        task.readonly = false;
      }
    }
  });
};

export function getBaselineDateRange(task: GanttTask): [Date, Date] {
  return [
    dayjs(task.baseline_start).startOf('day').toDate(),
    dayjs(task.baseline_end).add(1, 'day').startOf('day').toDate(),
  ];
}

export function getTaskDateRange(task: GanttTask): [Date, Date] {
  let startDate = dayjs(task.start_date).startOf('day').toDate();
  let endDate = dayjs(task.end_date).startOf('day').toDate();

  if (task.date_list?.length && task.object_type !== 'summary') {
    startDate = dayjs(task.date_list[0])?.startOf('day').toDate();
    endDate = dayjs(task.date_list[task.date_list.length - 1])
      ?.add(1, 'day')
      .startOf('day')
      .toDate();
  }

  return [startDate, endDate];
}

export function getVisibleDateRange(gantt: GanttStatic, dates: [Date, Date]): [Date, Date] {
  const [startDate, endDate] = dates;
  const timelineStart = new Date(gantt.config.start_date || gantt.getSubtaskDates()?.start_date);
  const timelineEnd = new Date(gantt.config.end_date || gantt.getSubtaskDates()?.end_date);
  return [startDate < timelineStart ? timelineStart : startDate, endDate > timelineEnd ? timelineEnd : endDate];
}

export function formatTaskDependencies(gantt: GanttStatic, task: GanttTask): string {
  if (!task.sourceDeps?.length) return '';
  return (
    task.sourceDeps
      .reduce((res, {depType, lagDays, predTaskId}) => {
        const depRowNumber = gantt.rownumbersMap?.[predTaskId];
        if (!depRowNumber) return res;
        let mask = `${depRowNumber}`;
        if (depType !== 'finish_start') {
          mask += TaskDependencyTypeAbbr[depType];
        }
        if (lagDays !== 0) {
          mask += `${lagDays > 0 ? `+${lagDays}` : lagDays}d`;
        }
        res.push({mask, row: depRowNumber});
        return res;
      }, [])
      // row numbers in ASC order
      .sort(function (a, b) {
        if (a.row === b.row) return 0;
        return a > b ? 1 : -1;
      })
      .map(({mask}) => mask)
      .join(',')
  );
}

type ParsedDependency = {
  rownum: number;
  depType: string;
  lagDays: number;
};

export function parseTaskDependencies(gantt: GanttStatic, mask: string, t?: TFunction): ParsedDependency[] {
  const regExp = /(?<row>\d+)(?<depType>\w+)?(?<lag>[+-]\d+d?)?/gi;
  return mask
    .split(',')
    .map((part) => part.trim())
    .reduce((acc, part) => {
      const parsed = Array.from(part.matchAll(regExp)).pop();
      if (parsed) {
        const {row, depType = '', lag = ''} = parsed.groups;
        if (depType && !Object.values(TaskDependencyTypeAbbr).includes(depType.toUpperCase())) {
          // don't try to abstract out second argument of call to t() so later string
          // extractions won't blank translation.
          const errorMsg = t
            ? t('gantt:toast.error.predecessor.wrong_deptype', 'Invalid task dependency type. Use FS, SS, FF, SF')
            : 'Invalid task dependency type. Use FS, SS, FF, SF';
          throw new Error(errorMsg);
        }
        const rowLag = parseInt(lag);
        if (row) {
          acc.push({
            rownum: parseInt(row),
            depType: depType ? TaskDependencyTypeByAbbr[depType.toUpperCase()] : TaskDependencyTypeByAbbr['FS'],
            lagDays: isNaN(rowLag) ? 0 : rowLag,
          });
        }
      }
      return acc;
    }, [] as ParsedDependency[]);
}

export function getDependenciesDiff(gantt: GanttStatic, task: GanttTask, newDependencies: ParsedDependency[]) {
  const prevDeps = task.sourceDeps || [];
  const toRemove = [];
  const toAdd = [];
  const toUpdate = [];

  if (prevDeps.length) {
    for (const {depType, rownum, lagDays} of newDependencies) {
      const existed = prevDeps.find((p) => p.rownum === rownum);
      const predTask = getTaskByRowNumber(gantt, rownum);
      if (existed) {
        if (existed.depType !== depType || existed.lagDays !== lagDays) {
          toUpdate.push({id: existed.id, depType, lagDays, predTaskId: predTask?.id});
        }
      } else if (predTask) {
        toAdd.push({predTaskId: predTask?.id, taskId: task.id, depType, lagDays});
      }
    }
    // find dependencies to remove
    for (const dep of prevDeps) {
      const found = newDependencies.find(({rownum}) => rownum === dep.rownum);
      if (!found && !toRemove.includes(dep.id)) {
        toRemove.push(dep.id);
      }
    }
  } else {
    for (const {depType, rownum, lagDays} of newDependencies) {
      const predTask = getTaskByRowNumber(gantt, rownum);
      toAdd.push({predTaskId: predTask?.id, taskId: task.id, depType, lagDays});
    }
  }

  return {
    add: toAdd,
    update: toUpdate,
    remove: toRemove,
  };
}

/*
 * Mark gantt in rendering process for cypress
 * */
export function trackRerenderingClassname(eventStore: EventStore<GanttEventName>, gantt: GanttStatic) {
  const CY_RERENDER_CLASSNAME = 're-render';
  if (window.Cypress) {
    eventStore.attach('onBeforeDataRender', () => {
      gantt.$root.classList.add(CY_RERENDER_CLASSNAME);
    });
    eventStore.attach(
      'onDataRender',
      debounce(() => {
        gantt.$root.classList.remove(CY_RERENDER_CLASSNAME);
      }, 2000),
    );
  }
}

export function getTaskByRowNumber(gantt: GanttStatic, row: number): GanttTask {
  return gantt.getTaskBy('rownum', row)?.[0];
}

export function prepareTaskGanttChangedFields(task: TaskGanttModel): Record<string, unknown> {
  const res = {};
  Object.entries(task.lastChangedFields).forEach(([key, {newValue}]) => {
    res[key] = newValue;
  });
  return camelizeKeys(res, (key, convert) => (key.includes('-') ? key : convert(key))) as Record<string, unknown>;
}

export function registerInlineEditorsListeners(
  gantt: GanttStatic,
  eventStore: EventStore<InlineEditorEventName>,
  mixpanel: ReturnType<typeof useMixpanel>,
) {
  eventStore.attach('onBeforeEditStart', ({id, columnName}) => {
    if (gantt.isTaskExists(id)) {
      const task = gantt.getTask(id) as GanttTask;
      if (isPlaceholderTask(gantt, task)) {
        gantt.refreshTask(task.id);
        mixpanel.track(mixpanel.events.tasks.creteNewTaskEmptyRow, {taskType: TaskObjectType.activity});
        return true;
      }
      if (task.type === TaskObjectType.milestone) {
        if (
          (task.object_subtype === TaskObjectSubType.end && columnName === GANTT_COLUMNS_NAMES.startDate) ||
          (task.object_subtype === TaskObjectSubType.start && columnName === GANTT_COLUMNS_NAMES.endDate)
        ) {
          return false;
        }
      }

      const isWBS = task.object_type === TaskObjectType.summary;
      const notEditableWbsColumn = NOT_EDITABLE_COLUMNS[task.object_type]?.includes(columnName) || isWBS;
      if (!(task.readonly || notEditableWbsColumn)) {
        mixpanel.track(mixpanel.events.gantt.inlineEdit[camelize(columnName)], {taskType: task.object_type});
        return true;
      }
    }
    return false;
  });

  eventStore.attach('onBeforeSave', ({id, columnName, oldValue, newValue}) => {
    const columnConfig = gantt.getGridColumn(columnName);
    const task: GanttTask = gantt.getTask(id);

    if (columnsForTrackingMixpanel.includes(columnName)) {
      mixpanel.track(mixpanel.events.gantt.inlineEdit[camelize(columnName)]);
    }

    if (columnConfig.editor.map_to === 'auto') {
      return true;
    }

    if (!task.lastChangedFields) {
      task.lastChangedFields = {};
    }

    const isProjectCustomField = columnConfig.editor.map_to === GANTT_COLUMNS_NAMES.customFields;
    if (isProjectCustomField) {
      return updateTaskCustomFieldValue(task, {field: columnName, value: newValue});
    }

    if (columnName === GANTT_COLUMNS_NAMES.duration && task.datesIsPristine && newValue) {
      task.datesIsPristine = false;
      gantt.refreshTask(id);
    }

    if (oldValue !== newValue && gantt.isTaskExists(id)) {
      task.lastChangedFields[columnName] = {oldValue, newValue};
    }
    return true;
  });
  eventStore.attach('onEditStart', function () {
    const inlineState = gantt.ext.inlineEditors.getState();
    let targetEl = inlineState.placeholder;
    // so that cell borders don't overlap
    targetEl.style.height = targetEl.offsetHeight - 1 + 'px';
    targetEl.style.zIndex = '1';

    inlineState.editor.valueOnStartEdit = inlineState.editor.get_value(
      inlineState.id,
      inlineState.columnName,
      targetEl,
    );

    if (inlineState.editor?.get_input) {
      const input = inlineState.editor.get_input(inlineState.placeholder);
      if (input) {
        // sometimes input width is larger than the container (e.g. date inputs)
        if (input.clientWidth >= targetEl.clientWidth) {
          targetEl = input;
        }
      }
    }
    const resizer = document.querySelector('.gantt_layout_content.gantt_resizer_x');
    if (resizer) {
      const targetElRect = targetEl.getBoundingClientRect();
      const resizerRect = resizer.getBoundingClientRect();
      if (targetElRect.right > resizerRect.left) {
        const horizScroll = document.querySelector('.gantt_layout_cell.gantt_hor_scroll');
        setTimeout(() => {
          horizScroll.scrollLeft += targetElRect.right - resizerRect.left;
        }, 1);
      }
    }
  });
}

export function isPlaceholderTask(gantt: GanttStatic, task: GanttTask) {
  return gantt.config.types.placeholder === task?.type;
}

export function deselectAll(gantt: GanttStatic) {
  gantt.eachSelectedTask(gantt.toggleTaskSelection);
  gantt.dRender();
}

export function toggleOpen(gantt: GanttStatic, open = true) {
  gantt.batchUpdate(() => {
    gantt.eachTask((task) => {
      task.$open = open;
    });
  });
}

export function isNotEditableTask(task: GanttTask): boolean {
  return task.readonly || !!task?.time_removed || task.status === TaskStatus.archived;
}

const isIncludedInDateRange = (targetDate: Date, startDate: Date, endDate: Date | null) => {
  const target = dayjs(targetDate);
  const beforeToday = target.isBefore(new Date(), 'd') || target.isSame(new Date(), 'd');
  const beforeIssueEnd = target.isBefore(endDate, 'd') || !endDate;
  const afterIssueStart = target.isAfter(startDate, 'd');
  const sameStartOrEnd = target.isSame(startDate, 'd') || target.isSame(endDate, 'd');
  return (afterIssueStart && beforeIssueEnd && beforeToday) || (sameStartOrEnd && beforeToday);
};

export const getQuantityIssuesInDay = (gantt: GanttStatic, openIssueIds: string[], taskDate: Date) => {
  return openIssueIds.reduce((acc, issueId) => {
    const issue = gantt?.issuesDictionary?.[issueId];
    if (issue && isIncludedInDateRange(taskDate, issue.startDate, issue.endDate)) {
      ++acc;
    }
    return acc;
  }, 0);
};

export function startInlineEditing(gantt: GanttStatic, node: Element): boolean {
  const inlineEditors = gantt.ext.inlineEditors;
  const state = inlineEditors.getState();
  const cell = inlineEditors.locateCell(node);

  if (cell && inlineEditors.getEditorConfig(cell.columnName)) {
    if (inlineEditors.isVisible() && state.id === cell.id && state.columnName === cell.columnName) {
      // do nothing if editor is already active in this cell
      return false;
    }

    const task = gantt.getTask(cell.id);
    if (state.columnName === GANTT_COLUMNS_NAMES.duration && task.object_type === TaskObjectType.summary) {
      return false;
    }
    inlineEditors.startEdit(cell.id, cell.columnName);
    return true;
  }
  return false;
}

export const getGridRowClass = (gantt: GanttStatic) => {
  return (start: Date, end: Date, task: GanttTask) => {
    return classnames({
      'selected-task_custom': task.openedEditPanel,
      'gantt__row_has-child': task.$has_child,
      'gantt__row_is-child': task.parent !== gantt.config.root_id,
      [`wbs-level__${task.$level > FOURTH_LEVEL ? FOURTH_LEVEL : task.$level}`]:
        task.object_type === TaskObjectType.summary,
      ['gantt__row_is_pending']: task.isPending,
    });
  };
};

import {createAsyncThunk} from '@reduxjs/toolkit';
import {batch} from 'react-redux';

import {SubscriptionApi, WorkerApi} from 'api';
import {sortAlphabetically} from 'shared/helpers/common';
import {CompanyModel, CompanyStatus} from 'shared/models/company';
import {Worker} from 'shared/models/worker';
import {RootThunk} from 'store';

import {getActiveCompanyFromLocalStorage} from './utils';

import {profileActions} from './index';

let RESUME_DATA_VERSION = 0;
export const saveResumeChanges = (payload?: Worker): RootThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(profileActions.setWorkerResumeIsSaving(true));

      // Remove null values from object and remove fields that are not not updated
      const deleteNullFields = (payload: object) => {
        for (const key of Object.keys(payload)) {
          if (payload[key] === null || payload[key] === state.profile.worker[key]) {
            delete payload[key];
          }
        }
      };

      deleteNullFields(payload);
      const dataVersion = ++RESUME_DATA_VERSION;
      const updatedWorker = await WorkerApi.updateWorkerData(payload, state.profile.worker.id);
      batch(() => {
        if (RESUME_DATA_VERSION === dataVersion) {
          dispatch(profileActions.setWorker(updatedWorker));
        }
        dispatch(profileActions.setWorkerResumeIsSaving(false));
      });
      return updatedWorker || state.profile.worker;
    } catch (error) {
      batch(() => {
        dispatch(profileActions.setWorkerIsLoading(false));
      });
      throw error;
    }
  };
};

export const loadWorker = (workerId: string): RootThunk<Promise<void>> => {
  return async (dispatch) => {
    const worker = await WorkerApi.getWorkerById(workerId);
    dispatch(profileActions.setWorker(worker));
  };
};

export const loadWorkerCompanies = (workerId: string): RootThunk<Promise<CompanyModel[]>> => {
  return async (dispatch) => {
    const companies = await WorkerApi.getWorkerCompanies(workerId);
    dispatch(profileActions.setWorkerCompanies(companies));
    return companies;
  };
};

export const loadWorkerCompanyWorkers = createAsyncThunk('worker/getWorkerCompanyWorkers', async (workerId: string) => {
  return await WorkerApi.getWorkerCompanyWorkers(workerId);
});

export function detectActiveWorkspace(): RootThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const companies = state.profile.companies;
    if (state.profile.worker && companies?.length) {
      const sortedAdminCompanies = sortAlphabetically(companies.slice(), 'companyName');
      const localStorageCompanyId = getActiveCompanyFromLocalStorage(state.profile.worker.id);
      let company = sortedAdminCompanies.find(
        (company) => company.id === localStorageCompanyId && company.status === CompanyStatus.Active,
      );
      if (!company) {
        // check has at least 1 active company
        company = sortedAdminCompanies.find((company) => company.status === CompanyStatus.Active);
      }
      // it means the user doesn't have any active company
      if (!company) {
        dispatch(profileActions.showAccessDeniedPopup(true));
      }

      dispatch(profileActions.setActiveCompany(company ? company.id : sortedAdminCompanies[0].id));
    }
  };
}

export const getCompanySubscriptions = createAsyncThunk('company/getSubscriptions', async (companyId: string) => {
  return SubscriptionApi.getSubscriptions(companyId);
});

export const getProPlans = createAsyncThunk('profile/getProPlan', async () => {
  return SubscriptionApi.getProPlan();
});

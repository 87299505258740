import {
  DAILIES_TASK_DEFAULT_FILTER_PARAMS,
  GANTT_TASK_DEFAULT_FILTER_PARAMS,
  GANTT_VISUAL_DEFAULT_FILTER_PARAMS,
  ISSUES_TASK_DEFAULT_FILTER_PARAMS,
  LOOKAHEAD_TASK_DEFAULT_FILTER_PARAMS,
  TASK_DEFAULT_FILTER_PARAMS,
} from 'modules/Tasks/components/Filters/utils/constants';
import {FiltersState} from 'modules/Tasks/components/Filters/utils/types';
import {TasksViewMode} from 'shared/constants/common';
import {deleteNullOrEmptyFields} from 'shared/helpers/common';
import {isSame} from 'shared/helpers/dates';
import {TaskFilterQuery, TaskStates} from 'shared/models/task';

function getFilterDirtyFields<T>(queryParams: Partial<T>, defaultParams: T) {
  return (Object.keys(defaultParams) as (keyof T)[]).filter((param) => {
    return queryParams[param] instanceof Date
      ? !isSame(defaultParams[param] as Date, queryParams[param] as Date)
      : param in queryParams && queryParams[param] !== defaultParams[param];
  });
}

export function isFilterFieldsDirty<T>({
  queryParams,
  fields,
  exclude,
  strict,
  exact,
  initialValues,
}: {
  queryParams: Partial<T>;
  fields?: (keyof T)[];
  exclude?: (keyof T)[];
  strict?: boolean; // if true all fields should be dirty
  exact?: boolean; // if true only specified fields should be dirty
  initialValues: T;
}) {
  const filterDirtyFields = getFilterDirtyFields<T>(queryParams, initialValues);
  const targetDirtyFields = filterDirtyFields.filter(
    (field) => (!fields || fields.includes(field)) && (!exclude || !exclude.includes(field)),
  );
  // eslint-disable-next-line no-console
  const isStrict = fields?.length === targetDirtyFields?.length;
  if (exact && fields) {
    return filterDirtyFields.length === fields.length && isStrict;
  }
  if (strict) {
    return isStrict;
  }
  return !!targetDirtyFields.length;
}

export function getFilterNumberFilledFields(queryParams: Partial<TaskFilterQuery>, viewMode: TasksViewMode) {
  const dirtyFields = getFilterDirtyFields(queryParams, getDefaultFilterParamsByViewMode(viewMode));
  let count = dirtyFields.length;
  // exclude pair filters
  if (dirtyFields.filter((key) => key.startsWith('schedStart')).length === 2) {
    count--;
  }
  if (dirtyFields.filter((key) => key.startsWith('schedEnd')).length === 2) {
    count--;
  }
  if (dirtyFields.includes('schedWeeks') || dirtyFields.includes('schedEndFirst')) {
    count--;
  }

  return count;
}

export function getDefaultFilterParamsByViewMode(viewMode: TasksViewMode) {
  switch (viewMode) {
    case TasksViewMode.lookahead:
      return LOOKAHEAD_TASK_DEFAULT_FILTER_PARAMS;
    case TasksViewMode.gantt:
      return GANTT_TASK_DEFAULT_FILTER_PARAMS;
    case TasksViewMode.ganttVisual:
      return GANTT_VISUAL_DEFAULT_FILTER_PARAMS;
    case TasksViewMode.issues:
      return ISSUES_TASK_DEFAULT_FILTER_PARAMS;
    case TasksViewMode.dailies:
      return DAILIES_TASK_DEFAULT_FILTER_PARAMS;
    default:
      return TASK_DEFAULT_FILTER_PARAMS;
  }
}

export function getInitialValues(initialValues?: Partial<TaskFilterQuery>): FiltersState {
  const incoming: Partial<TaskFilterQuery> = initialValues ? deleteNullOrEmptyFields({...initialValues}) : {};
  return Object.values(TasksViewMode).reduce(
    (acc, viewMode) => ({
      ...acc,
      [viewMode]: {
        active: {...getDefaultFilterParamsByViewMode(viewMode), ...incoming, view: viewMode},
        deleted: {
          ...getDefaultFilterParamsByViewMode(viewMode),
          ...incoming,
          view: viewMode,
          state: TaskStates.deleted,
        },
      },
    }),
    {} as FiltersState,
  );
}

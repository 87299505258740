import dayjs from 'dayjs';
import {GanttStatic} from 'dhtmlx-gantt';
import {forwardRef, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {CtrlButton, Dropdown} from 'shared/components/CoreNewUI';
import {CtrlDropdownActions} from 'shared/components/CoreNewUI/CtrlDrop/CtrlDrop';
import Tooltip from 'shared/components/Tooltip';
import {GanttNames} from 'shared/constants/gantt';
import {useRootSelector} from 'store';
import {getAppliedBaselineDate} from 'store/tasks/selectors';

import {isBaseLineMode} from '../../../../Views/Gantt/utils/baselineHandlers';

import s from './BaselineDropdown.module.scss';
import Baseline from './components/Baseline/Baseline';

type Props = {
  gantt: GanttStatic;
  disabled?: boolean;
  viewportPosition?: 'center' | 'left';
};

// BaselineDropdownd doesn't require ref at this time, but one parent that renders
// it as a child does pass ref, this avoids a console error on startup.
const BaselineDropdown = forwardRef<HTMLElement, Props>(
  (
    {gantt, disabled, viewportPosition},
    ref, // eslint-disable-line @typescript-eslint/no-unused-vars
  ) => {
    const dropdownRef = useRef<CtrlDropdownActions>();
    const appliedBaselineDate = useRootSelector((state) => getAppliedBaselineDate(state)) as Date;
    const {t} = useTranslation('gantt');

    const closeDropdown = () => {
      dropdownRef.current?.close();
    };

    return (
      <>
        <Dropdown
          excludeClassOutsideClick={['react-datepicker']}
          ref={dropdownRef}
          disabled={disabled}
          closeIconStyle={{color: '#FA380D', opacity: 0.5, width: 24}}
          viewportAlwaysInPage
          toggleElement={
            <div>
              <Tooltip text={t('baseline.title', 'Baseline')} placement="bottom" key="Baseline">
                <CtrlButton color="action" size="xs" icon="baseline" iconOnly></CtrlButton>
              </Tooltip>
            </div>
          }
          header={{
            title: t('baseline.title', 'Baseline'),
            buttonClose: true,
          }}
          menuWidth={325}
          viewportPosition={viewportPosition || 'center'}
          viewportStyle="popup"
        >
          <Baseline gantt={gantt} closeDropdown={closeDropdown} />
        </Dropdown>
        {isBaseLineMode() && gantt.name === GanttNames.gantt && (
          <div onClick={(e) => dropdownRef.current?.toggle(e)} className={s.baselineDropdown__titleDate}>
            {dayjs(appliedBaselineDate).isValid() && dayjs(appliedBaselineDate).format('MMM D, YYYY')}
          </div>
        )}
      </>
    );
  },
);
export default BaselineDropdown;
BaselineDropdown.displayName = 'BaselineDropdown';
